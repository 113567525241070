import { Box, Typography } from '@mui/material';
import React from 'react';

import { logger } from '@/utils';

import { Types } from '../exchange-calculator.types';
import { useStyles } from './pricing.styles';
import { PricingProps } from './pricing.types';

export const Pricing: React.FC<PricingProps> = ({
  isRateLoading,
  countriesLoading,
  amountTypeRef,
  pricing,
  values,
  receiveCountry,
  sendCountry,
  limitReached,
  selectedSegment,
  fetchRate,
  setValues,
  setLimitReached,
  fixMultiplePayoutCurrency,
  locale,
}) => {
  const classes = useStyles();

  React.useEffect(() => {
    if (isRateLoading || countriesLoading) return;

    const amountType = amountTypeRef.current;
    const oppositeType = amountType === Types.SEND ? Types.RECEIVE : Types.SEND;
    const amount =
      amountTypeRef.current === Types.SEND
        ? pricing?.receiveAmount
        : pricing?.effectiveSendAmount;

    if (!values[amountTypeRef.current]) {
      return setValues(prevValues => ({
        ...prevValues,
        [oppositeType]: '_',
      }));
    }

    // This will be reused should will decide that we wanna enable send limits so the code cannot be deleted
    // if (sendCountry?.sendLimits && pricing) {
    //   if (pricing.effectiveSendAmount > sendCountry.sendLimits.default.day) {
    //     return setLimitReached(true);
    //   }
    // }

    setLimitReached(false);
    amount &&
      setValues(prevValues => ({
        ...prevValues,
        [oppositeType]: amount,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countriesLoading,
    isRateLoading,
    pricing,
    sendCountry?.sendLimits?.default.day,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[amountTypeRef.current],
  ]);

  React.useEffect(() => {
    try {
      if (
        !sendCountry ||
        !receiveCountry ||
        !sendCountry.currency ||
        !receiveCountry.currency ||
        countriesLoading
      )
        return;

      const amountType = amountTypeRef.current;
      const payload = {
        amountType,
        receiveCurrency: receiveCountry.currency,
        segmentName: selectedSegment?.bestPricedSegmentName,
        amount: values[amountType],
        sendCurrency: sendCountry.currency,
        sendCountryIso2: sendCountry.iso2,
        receiveCountryIso2: receiveCountry.iso2,
      };

      fetchRate(payload, locale);
      fixMultiplePayoutCurrency();
    } catch (error) {
      logger.error(error, `Failed to fetch payment methods`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sendCountry,
    receiveCountry,
    selectedSegment,
    countriesLoading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[amountTypeRef.current],
    fetchRate,
    fixMultiplePayoutCurrency,
    locale,
  ]);

  return (
    <>
      {pricing?.rateQuote && !limitReached && (
        <Box className={classes.exchangeRateContainer}>
          <Typography
            dangerouslySetInnerHTML={{ __html: pricing.rateQuote }}
            className={classes.exchangeRateHtmlRender}
            data-testid={'exchangerate-text'}
          />
        </Box>
      )}
    </>
  );
};
