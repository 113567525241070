import { Typography } from '@mui/material';
import { TrustpilotWidget as UITrustpilotWidget } from '@wr/web-ui';

import { Container } from '../../../ui/src/components/container';
import { Carousel } from './carousel';
import { ReviewsCard, ReviewsCardProps } from './reviews-card';
import { REVIEWS_SECTION_CAROUSEL_SETTINGS } from './reviews-section.constant';
import { useStyles } from './reviews-section.styles';
import { ReviewsSectionProps } from './reviews-sections.types';

export const ReviewsSection: React.FC<ReviewsSectionProps> = ({
  name,
  header,
  backgroundImage,
  reviewsCardCollection,
  trustpilotWidget,
}) => {
  const classes = useStyles({ backgroundImage: backgroundImage?.url });

  return (
    <section id={name || 'ReviewsSection'} className={classes.section}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.header}>
          {header}
        </Typography>
        {trustpilotWidget && (
          <div
            data-testid="reviews-trustpilot-widget"
            className={classes.trustpilotWidget}
          >
            <UITrustpilotWidget url={trustpilotWidget.url as string} />
          </div>
        )}
        <Carousel
          settings={{
            ...REVIEWS_SECTION_CAROUSEL_SETTINGS,
          }}
        >
          {reviewsCardCollection?.items?.map(
            (reviewsCard: ReviewsCardProps | null) =>
              reviewsCard ? (
                <ReviewsCard key={reviewsCard?.sys.id} {...reviewsCard} />
              ) : (
                <></>
              ),
          )}
        </Carousel>
      </Container>
    </section>
  );
};
