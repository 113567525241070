import { contentfulSchema } from '@wr/web-shared';
import { ListOfCountries as UIListOfCountries } from '@wr/web-ui';
import React, { useMemo } from 'react';

import { useCountriesStore } from '@/state';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Button, ButtonProps } from '../button';

export const ListOfCountries: React.FC<contentfulSchema.ListOfCountriesFragment> = ({
  sys,
  __typename,
  name,
  header,
  subheader,
  countriesCollection,
  buttonLabelHiddenList,
  buttonLabelExpandedList,
}) => {
  const countries = useCountriesStore(state => state.countries);

  const filteredReceiveCountries = useMemo(() => {
    const filteredCountries = countries?.filter(country => country.receive);

    return filteredCountries?.map(country => {
      const countryCode = country.iso2.toUpperCase();
      const slug = country.name.replace(/\s+/g, '-').toLowerCase();

      return {
        receiveCountry: {
          [countryCode]: country.name,
        },
        countriesByCode: {
          [countryCode]: slug,
        },
      };
    });
  }, [countries]);

  const allCountries = useMemo(
    () =>
      filteredReceiveCountries?.map(({ receiveCountry, countriesByCode }) => {
        const code = Object.keys(receiveCountry)[0];

        return {
          code: code.toLowerCase(),
          name: receiveCountry[code],
          variant: 'contained',
          fullWidth: true,
          url: `countries/${countriesByCode[code]}`,
          color: 'primary',
        };
      }),
    [filteredReceiveCountries],
  );

  return (
    <UIListOfCountries<ButtonProps>
      name={name || undefined}
      header={header || null}
      subheader={subheader || undefined || null}
      buttonLabelExpandedList={buttonLabelExpandedList || null}
      buttonLabelHiddenList={buttonLabelHiddenList || null}
      allCountries={allCountries || []}
      visibleCountriesCodes={
        countriesCollection?.items.map(c => c?.code || '') || []
      }
      ButtonComponent={Button}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
