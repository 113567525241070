import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(
  ({ spacing, breakpoints, palette }) => ({
    container: {
      padding: spacing(2, 0),
    },
    alphabetContainer: {
      'display': 'grid',
      'gridTemplateColumns': '1fr auto 1fr',
      'alignItems': 'center',
      'gap': spacing(1),

      '&::before, &::after': {
        content: '""',
        background: colors.greyLight,
        width: '100%',
        height: '2px',
      },
    },
    alphabetText: {
      display: 'flex',
      width: spacing(7),
      height: spacing(7),
      borderRadius: '50%',
      color: palette.primary.contrastText,
      backgroundColor: colors.greyLight,
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 700,
    },
    gridContent: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      padding: spacing(4, 0),
      gap: spacing(2),

      [breakpoints.down('lg')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },

      [breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },

      [breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
  }),
);
