import ExpandMoreIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Accordion, Container as UIContainer, Image } from '@wr/web-ui';
import { FC, useState } from 'react';

import { Anchor } from '@/components';
import { useAccordionToggle } from '@/hooks';

import { useStyles } from './nested-accordion.styles';
import { NestedAccordionProps } from './nested-accordion.types';
import { NestedAccordionDetails } from './nested-accordion-details';

export const NestedAccordion: FC<NestedAccordionProps> = ({
  title,
  backgroundColor,
  nestedAccordionItems,
}) => {
  const classes = useStyles({ backgroundColor });
  const [expandedPanel, setExpandedPanel] = useState<string | undefined>();
  const [subExpandedPanel, setSubExpandedPanel] = useState<
    string | undefined
  >();

  const { handleSubPanelIcon, handleToggleAccordion } = useAccordionToggle(
    expandedPanel,
    subExpandedPanel,
  );

  return (
    <section className={classes.sectionWrapper} data-testid="nested-accordion">
      <UIContainer className={classes.gridWrapper}>
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>

        <div className={classes.nestedAccordionItemsWrapper}>
          {nestedAccordionItems.items.map((nestedAccordionItem, index) => {
            const panelIndex = `panel_${index}`;

            return (
              <Accordion
                className={classes.accordion}
                data-testid={`nested-accordion-item-${nestedAccordionItem.sys.id}`}
                expanded={expandedPanel === panelIndex}
                onChange={() =>
                  handleToggleAccordion(setExpandedPanel, panelIndex)
                }
                key={nestedAccordionItem.sys.id}
              >
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Image
                    {...nestedAccordionItem.icon}
                    className={classes.accordionSummaryAvatar}
                  />
                  <div>
                    <Typography
                      variant="h3"
                      className={classes.accordionSummaryTitle}
                    >
                      {nestedAccordionItem.title}
                    </Typography>
                  </div>
                </AccordionSummary>

                <AccordionDetails className={classes.accordionDetails}>
                  {nestedAccordionItem.nestedAccordionLinks.items.map(link => (
                    <div key={link.sys.id}>
                      <Anchor
                        url={link.slug}
                        data-testid={`nested-accordion-item-link-${link.sys.id}`}
                        className={classes.accordionDetailsLink}
                        underline="none"
                      >
                        <span className={classes.accordionDetailsLinkText}>
                          {link.title}
                        </span>
                        <ArrowRightAltIcon />
                      </Anchor>
                    </div>
                  ))}
                </AccordionDetails>

                <NestedAccordionDetails
                  subtitles={nestedAccordionItem.subtitles}
                  classes={classes}
                  handleToggleAccordion={handleToggleAccordion}
                  handleSubPanelIcon={handleSubPanelIcon}
                  subExpandedPanel={subExpandedPanel}
                  setSubExpandedPanel={setSubExpandedPanel}
                />
              </Accordion>
            );
          })}
        </div>
      </UIContainer>
    </section>
  );
};
