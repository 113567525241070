import { BLOCKS } from '@contentful/rich-text-types';
import { Theme, useMediaQuery } from '@mui/material';
import { APP_NAME, INTERACTION_NAME, pushToDataLayer } from '@wr/web-shared';
import { Hero as UIHero, Image } from '@wr/web-ui';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';

// import { Calculator } from '@/components/calculator/component/calculator.component';
// import { FaqSearch } from '@/components/faq-search';
import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { getRichTextHeadingOptions } from '@/components/rich-text-content-renderer/rich-text-content-renderer.utils';
import { AppContext } from '@/context';
import { colors } from '@/styles';
// import { SendToCountryModule } from '@/components/send-to-country-module';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Anchor, AnchorProps } from '../anchor';
import { Button, ButtonProps } from '../button';
import { ExchangeCalculator } from '../exchange-calculator';
import { useStyles } from './hero.styles';
import { HeroProps } from './hero.types';

const QR_CODE_ID = 'hero-qr-code';

export const Hero: React.FC<HeroProps> = ({
  sys,
  name,
  __typename,
  rightModule,
  headingRichText,
  subHeadingRichText,
  link,
  foregroundImage,
  backgroundImage,
  backgroundImageMobile,
  bottomWaveImage,
  trustpilotMessage,
  trustpilotLogo,
  trustpilotLink,
  trustpilotWidget,
  qrCode,
  isSectionOnTop,
  backgroundColor,
  secondBackgroundColor,
  appStoreIconLink,
  playStoreIconLink,
  hideDescriptionOnMobile,
}) => {
  const { locale, analyticsPageType } = useContext(AppContext);
  const [fullUrl, setFullUrl] = useState('');

  useEffect(() => {
    setFullUrl(window.location.href);
  }, []);

  const dataLayerProps = {
    event: INTERACTION_NAME.DOWNLOAD_APP_CLICK,
    page_type: analyticsPageType,
    page_name: fullUrl,
    page_language: locale,
  };

  const onClickAppStoreLink = (): void => {
    pushToDataLayer({ ...dataLayerProps, app_name: APP_NAME.IOS });
  };

  const onClickPlayStoreLink = (): void => {
    pushToDataLayer({ ...dataLayerProps, app_name: APP_NAME.ANDROID });
  };

  const classes = useStyles();
  const { asPath } = useRouter();
  const isSMDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const image = isSMDown
    ? backgroundImageMobile || backgroundImage
    : backgroundImage;

  const fullCalculatorUrl = 'full-calculator' + asPath.substring(1);

  const qrCodeProps = {
    url: qrCode?.imageUrl,
    description: qrCode?.description,
    classes,
    elementId: QR_CODE_ID,
  };

  const getRightColumnComponent = (): React.ReactNode => {
    switch (rightModule?.__typename) {
      case 'ExchangeCalculator': {
        const calculator = rightModule;
        return <ExchangeCalculator {...calculator} />;
      }
      // case 'Search':
      //   return <FaqSearch {...rightModule} />;
      // case 'SendToCountryModule':
      //   return <SendToCountryModule {...rightModule} />;
      default:
        return null;
    }
  };

  const hasRightColumn = false;

  return (
    <UIHero<AnchorProps, ButtonProps, RichTextProps>
      name={name || undefined}
      heading={
        headingRichText?.json
          ? {
              ...headingRichText,
              options: {
                ...getRichTextHeadingOptions({
                  color: colors.sendwaveBrown,
                  className: clsx(classes.heading, {
                    [classes.textCenter]: hasRightColumn,
                    [classes.fullWidthHeading]: !hasRightColumn,
                  }),
                }),
              },
            }
          : null
      }
      subheading={
        subHeadingRichText?.json
          ? {
              ...subHeadingRichText,
              options: {
                [BLOCKS.HEADING_5]: {
                  // component: 'p',
                  color: colors.sendwaveBrown,
                  variant: 'body2',
                },
                [BLOCKS.HEADING_6]: {
                  // component: 'p',
                  color: colors.sendwaveBrown,
                  variant: 'body1',
                  className: classes.heading6,
                },
                [BLOCKS.PARAGRAPH]: {
                  variant: 'h4',
                  color: colors.sendwaveBrown,
                  className: clsx(classes.subheading, {
                    [classes.textCenter]: hasRightColumn,
                    [classes.fullWidthSubHeading]: !hasRightColumn,
                  }),
                },
              },
            }
          : null
      }
      button={
        link?.label
          ? { ...link, children: link.label, url: fullCalculatorUrl }
          : null
      }
      foregroundImage={
        foregroundImage
          ? {
              ...foregroundImage,
              loading: 'eager',
            }
          : null
      }
      hideDescriptionOnMobile={hideDescriptionOnMobile || false}
      backgroundImage={
        image
          ? {
              ...image,
              loading: 'eager',
              fill: true,
              priority: true,
              style: {
                objectFit: 'cover',
                objectPosition: 'top left',
              },
            }
          : null
      }
      bottomImage={
        bottomWaveImage
          ? {
              ...bottomWaveImage,
              loading: 'eager',
              priority: true,
              style: {
                bottom: 0,
                left: 0,
                position: 'absolute',
                width: '100%',
                height: 'auto',
              },
            }
          : null
      }
      qrCode={qrCodeProps}
      trustpilotMessage={trustpilotMessage || null}
      trustpilotLogo={trustpilotLogo || null}
      trustpilotLink={
        trustpilotLink?.label
          ? {
              ...trustpilotLink,
              children: trustpilotLink.label || undefined,
              underline: 'none',
              color: trustpilotLink.color || undefined,
            }
          : null
      }
      trustpilotWidgetUrl={trustpilotWidget?.url || null}
      AnchorComponent={Anchor}
      ButtonComponent={Button}
      RichTextComponent={RichTextContentRenderer}
      isSectionOnTop={isSectionOnTop}
      backgroundColor={backgroundColor || null}
      secondBackgroundColor={secondBackgroundColor || null}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
      appStoreIconLink={
        appStoreIconLink
          ? {
              ...appStoreIconLink,
              'data-testid': 'app-banner-app-store-link',
              'openInNewTab': true,
              'isInternal': false,
              'onClick': onClickAppStoreLink,
              'children': appStoreIconLink?.image ? (
                <Image {...appStoreIconLink.image} fill={false} />
              ) : null,
            }
          : null
      }
      playStoreIconLink={
        playStoreIconLink
          ? {
              ...playStoreIconLink,
              'data-testid': 'app-banner-play-store-link',
              'openInNewTab': true,
              'isInternal': false,
              'onClick': onClickPlayStoreLink,
              'children': playStoreIconLink?.image ? (
                <Image {...playStoreIconLink.image} fill={false} />
              ) : null,
            }
          : null
      }
    >
      {getRightColumnComponent()}
    </UIHero>
  );
};
