// import { COOKIES } from '@wr/web-shared';
// import { getSingleValueFromQueryParam } from '@/utils';
import { GetServerSidePropsContext } from 'next';

/** Returns query variables: page type, slug and corridor countries
 * to fetch correct Page and any associated data with that corridor
 *
 * /en - slug = [], returns root home page
 * /en/gb - slug = [gb], returns localized home page, if doesn't exist redirects to root
 * /en/philippines - slug = [philippines] - check if slug is matches slugified country name and has unique page, returns it
 * /en/poland - slug = [poland] - as above, has no unique page, returns generic country page
 * /en/faq/foo - slug = [faq, foo] returns any non home/country/error page by slug, otherwise returns 404
 */
export const getContentfulPageQueryVariables = async ({
  params,
}: Pick<GetServerSidePropsContext, 'params'>) => {
  if (!params?.slug) {
    // Home Page
    return {
      slug: null,
    };
  }

  const slug = Array.isArray(params.slug) ? params.slug : [params.slug];

  // any other non home/country/error page
  const otherSlug = slug.join('/');

  return {
    slug: otherSlug,
  };
};
