import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment, useMemo } from 'react';

import { groupCorridorsByAlphabet } from '@/services/contentful/currency-data/currency-data.utils';

import { CexCorridorCard } from '../cex-corridor-card';
import { useStyles } from './cex-corridor-grid.styles';
import { CexCorridorGridProps } from './cex-corridor-grid.types';

export const CexCorridorGrid: React.FC<CexCorridorGridProps> = ({
  corridors,
  currencyCode,
}) => {
  const classes = useStyles();

  const groups = useMemo(() => {
    const grouping = groupCorridorsByAlphabet(corridors, currencyCode);
    return Object.keys(grouping)
      .map(groupCode => ({
        groupCode,
        groupData: grouping[groupCode],
      }))
      .sort((a, b) => a.groupCode.localeCompare(b.groupCode));
  }, [corridors, currencyCode]);

  return (
    <div className={classes.container} data-testid="cex-corridor-grid">
      {groups.map(({ groupCode, groupData }) => (
        <Fragment key={groupCode}>
          <Box className={classes.alphabetContainer}>
            <Typography
              component="div"
              className={classes.alphabetText}
              data-testid="cex-corridor-grid-group-code"
            >
              {groupCode}
            </Typography>
          </Box>

          <Grid className={classes.gridContent}>
            {groupData.map(corridor => (
              <CexCorridorCard
                key={corridor.pageSlug}
                corridor={corridor}
                currencyCode={currencyCode}
              />
            ))}
          </Grid>
        </Fragment>
      ))}
    </div>
  );
};
