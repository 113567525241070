import { Typography } from '@mui/material';
import { Container } from '@wr/web-ui';
import React from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { interpolate } from '@/utils';

import { CexCorridorGrid } from '../cex-corridor-grid';
import { CexCurrencyHero } from './cex-currency-profile-hero.component';
import { useStyles } from './cex-currency-profile-page.styles';
import { CurrencyProfilePageLayoutProps } from './cex-currency-profile-page.types';

export const CurrencyProfilePageLayout: React.FC<CurrencyProfilePageLayoutProps> = ({
  title,
  description,
  currencyInformation,
  currencyCorridors,
  header,
}) => {
  const classes = useStyles();

  return (
    <>
      <CexCurrencyHero
        title={interpolate(title, {
          currencyName: currencyInformation.currencyName ?? '',
          currencyCode: currencyInformation.currencyCode ?? '',
        })}
        description={
          description &&
          interpolate(description, {
            currencyName: currencyInformation.currencyName ?? '',
            currencyCode: currencyInformation.currencyCode ?? '',
          })
        }
        currencyInformation={currencyInformation}
        isSectionOnTop={header?.headerPosition === 'absolute'}
      />

      <Container className={classes.container} component="section">
        {currencyInformation?.description?.json && (
          <RichTextContentRenderer
            json={currencyInformation.description.json}
            links={
              currencyInformation?.faqContent?.links as RichTextProps['links']
            }
          />
        )}

        {currencyInformation?.faqContent?.json && (
          <RichTextContentRenderer
            json={currencyInformation.faqContent.json}
            links={
              currencyInformation?.faqContent?.links as RichTextProps['links']
            }
          />
        )}
      </Container>

      {currencyCorridors ? (
        <Container
          component="section"
          data-testid="cex-corridors-container"
          className={classes.container}
        >
          <Typography component="h2" variant="h2" className={classes.title}>
            {interpolate(currencyInformation.corridorGridTitle, {
              currencyName: currencyInformation.currencyName ?? '',
              currencyCode: currencyInformation.currencyCode ?? '',
            })}
          </Typography>
          <CexCorridorGrid
            corridors={currencyCorridors}
            currencyCode={currencyInformation.currencyCode}
          />
        </Container>
      ) : null}
    </>
  );
};
