import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Faq } from '@/state/sendwave/faq/faq.types';

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledAccordionSummaryTitle,
} from './faq-item.styles';

export const FaqItem: React.FC<Faq> = ({ id, title, body }) => {
  return (
    <div data-testid={`faq-item-${id}`}>
      <StyledAccordion data-testid={`faq-accordion-${id}`} key={`faq-${id}`}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <StyledAccordionSummaryTitle variant="h6">
            {title}
          </StyledAccordionSummaryTitle>
        </StyledAccordionSummary>
        <StyledAccordionDetails>{body}</StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};
