import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(0, 0.5),
    fontSize: '1.5rem', // 24px
    lineHeight: 1.16666666667, // 28px
    marginTop: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(5),
    },
  },
}));
