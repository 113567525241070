import { contentfulSchema } from '@wr/web-shared';

import { CountryData } from '@/services/rest/countries.service';

export type SendReceiveCountry = Record<string, CountryData>;

export type ExchangeCalculatorProps = Pick<
  contentfulSchema.ExchangeCalculator,
  | 'name'
  | 'title'
  | 'mobile'
  | 'bank'
  | 'cash'
  | 'heading'
  | 'description'
  | 'sendLabel'
  | 'receiveLabel'
  | 'limitReachedError'
  | 'exchangeRate'
>;

export const Types = {
  SEND: 'SEND',
  RECEIVE: 'RECEIVE',
} as const;
