import { CountryData } from '@/services/rest/countries.service';

export const replaceAccentCharactersWithStandard = (text: string): string =>
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const normalizeStr = (str: string): string =>
  replaceAccentCharactersWithStandard(str).trim().toLowerCase();

export const filterCountries = (
  countries: CountryData[],
  searchedStr?: string,
): CountryData[] => {
  if (!searchedStr) {
    return countries;
  }

  const normalizedSearchedStr = normalizeStr(searchedStr);

  return countries.filter(country => {
    const normalizeCurrency = normalizeStr(country.currency || '').includes(
      normalizedSearchedStr,
    );
    const normalizeCountryName = normalizeStr(country.name).includes(
      normalizedSearchedStr,
    );

    return normalizeCurrency || normalizeCountryName;
  });
};

export const removeScroll = (): void => {
  document.body.style.position = 'fixed';
  document.body.style.height = '100%';
  document.body.style.overflow = 'hidden';
  document.body.style.top = '0';
  document.body.style.bottom = '0';
  document.body.style.left = '0';
  document.body.style.right = '0';
};

export const sortArrayByProperty = <T,>(array: T[], property: keyof T): T[] =>
  array.sort((a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  });
