import { lighten, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  slider: {
    '&.slick-slider, & .slick-list': {
      position: 'relative',
      userSelect: 'none',
    },
    '&.slick-slider': {
      'display': 'block',

      '& .slick-arrow': {
        'display': 'flex !important',
        'justifyContent': 'center',
        'alignItems': 'center',
        'backgroundColor': colors.white,
        'width': '44px',
        'height': '44px',
        'border': `1px solid ${lighten(colors.grey, 0.7)}`,
        'borderRadius': '100%',
        'color': colors.sendwaveOrange,
        'cursor': 'pointer',
        'position': 'absolute',
        'top': '45%',
        'transform': 'translateY(-50%)',
        'zIndex': 2,

        '&.slick-prev': {
          left: '-18px',
        },

        '&.slick-next': {
          right: '-18px',
        },

        '& svg': {
          fontSize: '1.5rem',
        },
      },

      '& .slick-dots': {
        'display': 'flex !important',
        'justifyContent': 'center',
        'alignItems': 'center',
        'color': colors.grey,
        'marginTop': '66px',
        'width': '100%',
        'listStyle': 'none',
        'fontSize': 'smaller',

        '& li, & button': {
          display: 'flex',
        },
      },
    },
    '& .slick-list': {
      position: 'relative',
      overflow: 'visible',
      display: 'block',
      margin: theme.spacing(-1),

      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(-1.5),
      },
    },
    '& .slick-slide': {
      'minHeight': '1px',
      'padding': theme.spacing(1),

      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(1.5),
      },
      '& > div': {
        height: '100%',
      },
    },
    '& .slick-track': {
      display: 'flex',
      alignItems: 'stretch',
    },
  },
  paginationItem: {
    'width': '8px',
    'height': '8px',
    'border-radius': '100%',
    'background-color': colors.grey,
    'cursor': 'pointer',
    'margin': theme.spacing(0, 1),
    '.slick-active &': {
      backgroundColor: colors.sendwaveOrange,
      width: '17px',
      height: '17px',
    },
  },
}));
