import { AutocompleteProps } from '@mui/material/Autocomplete';
import { SearchOption } from '@wr/web-ui';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCexCurrencies } from '@/hooks';

import { CorridorProps } from './cex-search.types';
import { filterCurrencyOptions } from './cex-search.utils';

export const useSearchFilter = (
  props: Pick<CorridorProps, 'sendCountryCode' | 'corridors'>,
) => {
  const router = useRouter();

  const calculator = useCexCurrencies();

  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchResults, setSearchResults] = useState<SearchOption[]>([]);

  const currencies = useMemo(() => {
    return props?.sendCountryCode
      ? (props.corridors || [])
          .map(corridor =>
            props.sendCountryCode !== corridor.sendCurrency.countryCode
              ? corridor.sendCurrency
              : corridor.receiveCurrency,
          )
          //remove duplicates
          .filter(
            (currency, index, self) =>
              index ===
              self.findIndex(t => t.currencyCode === currency.currencyCode),
          )
      : calculator?.currencies;
  }, [calculator?.currencies, props.corridors, props?.sendCountryCode]);

  const onInputChange: AutocompleteProps<
    SearchOption,
    undefined,
    undefined,
    true
  >['onInputChange'] = (_, value) => {
    setSearchInputValue(value);
  };

  const onFilterOptions = (
    _: unknown,
    { inputValue }: { inputValue: string },
  ): SearchOption[] => {
    return filterCurrencyOptions(currencies, inputValue);
  };

  const onKeyDown = useCallback(
    (searchValue: string) => {
      const url = `/currency-converter/currencies?search=${searchValue}`;
      router.push(url);
    },
    [router],
  );

  /**
   * Update search results based on input value
   */
  useEffect(() => {
    setSearchResults(filterCurrencyOptions(currencies, searchInputValue));
  }, [currencies, searchInputValue]);

  return {
    currencies,
    searchResults,
    onFilterOptions,
    onInputChange,
    onKeyDown,
  };
};
