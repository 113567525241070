import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import React from 'react';
import { CustomArrowProps as SlickCustomArrowProps } from 'react-slick';

type CustomArrowProps = SlickCustomArrowProps & {
  variant: 'next' | 'prev';
};

export const CustomArrow: React.FC<CustomArrowProps> = props => {
  const { slideCount, currentSlide, variant, ...rest } = props;
  return (
    <div
      {...{
        ...rest,
        slidecount: slideCount,
        currentslide: currentSlide,
      }}
    >
      {variant === 'prev' && <ChevronLeft />}
      {variant === 'next' && <ChevronRight />}
    </div>
  );
};
