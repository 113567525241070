import { ANALYTICS_PAGE_TYPE, DEFAULT_LANGUAGE } from '@wr/web-shared';
import { getSendCountryCodeFromLocale } from '@wr/web-ui';
import { GetServerSidePropsContext } from 'next/types';

import { DEFAULT_LOCATION, DEFAULT_RECEIVE_COUNTRY } from '@/constants';
import { getPage, getPageData } from '@/services';
import { selectOrganizationMarkup } from '@/services/contentful';
import { getContentfulPageQueryVariables } from '@/services/contentful/page/page.utils';
import { getRedirects } from '@/services/contentful/redirects';
import { getLinksWithoutRedirects } from '@/services/contentful/redirects/redirect.utils';
import { getRegionCode } from '@/services/contentful/region-code/region-code.service';
import { Countries } from '@/services/rest';
import { getAllModuleItems, logger } from '@/utils';
import { getBreadcrumbs } from '@/utils/breadcrumbs.utils';

export const getDefaultServerSideProps = async ({
  preview = false,
  locale: nextLocale,
  defaultLocale,
  params,
  query,
}: GetServerSidePropsContext) => {
  try {
    let pageType;
    const region = (() => {
      if (nextLocale && nextLocale !== defaultLocale) return nextLocale;
      return DEFAULT_LANGUAGE;
    })();

    const sendCountryCode =
      getSendCountryCodeFromLocale(nextLocale) || DEFAULT_LOCATION;

    const receiveCountryCode = query?.to || DEFAULT_RECEIVE_COUNTRY;

    const { slug } = await getContentfulPageQueryVariables({
      params,
    });

    const page = await getPage({
      slug: slug,
      locale: region,
      region,
      preview,
    });

    if (!page) {
      logger.error({ slug }, 'Page was not found in contentful');

      return {
        notFound: true,
      };
    }

    const HP_REDIRECT_PROPS = {
      redirect: {
        destination: `/${region}`,
        permanent: true,
      },
    };

    if (pageType === ANALYTICS_PAGE_TYPE.Country) {
      return HP_REDIRECT_PROPS;
    }

    const regionCode = await getRegionCode();

    const [
      pageData,
      modules,
      { data: countries },
      pageRedirects,
    ] = await Promise.all([
      getPageData({
        pageId: page.sys.id,
        locale: region,
        slug: page?.slug || '',
        analyticsPageType: pageType ?? '',
        preview,
        sendCountryCode,
      }),
      getAllModuleItems({
        locale: region,
        modules: page?.modulesCollection?.items,
        preview,
      }),
      Countries.get(),
      getRedirects(),
    ]);
    const breadcrumbs = getBreadcrumbs(pageData);

    const regionSlug = page.regions?.map(region => ({
      slug: slug ? `${region}/${slug}` : region,
      title: region || '',
    }));

    const pageLinksWithRegionsAndSlugs = getLinksWithoutRedirects(
      regionSlug,
      pageRedirects,
    );

    const regions =
      page.regions?.filter((region): region is string => Boolean(region)) || [];

    const sendCountry = sendCountryCode
      ? countries?.find(country => country.iso2 === sendCountryCode) ?? null
      : null;

    const receiveCountry = receiveCountryCode
      ? countries?.find(country => country.iso2 === receiveCountryCode) ?? null
      : null;

    const organizationSchema = selectOrganizationMarkup(pageData, page);

    return {
      props: {
        sys: page.sys,
        __typename: page.__typename,
        name: page.name,
        title: page.title,
        description: page.description,
        noIndex: page.noIndex,
        footer: {
          ...page.footer,
          legalMessage: pageData?.legalMessagingCollection?.items[0],
        },
        header: {
          ...pageData?.layoutHeaderCollection?.items[0],
          submenuItems: page.submenuItemsCollection?.items,
          headerPosition: page.headerPosition,
          headerTheme: page.headerTheme,
        },
        breadcrumbs,
        modules,
        originUri: process.env.WEB_CMS_SW_URI,
        countries: countries || [],
        regions,
        messages: pageData?.messagesCollection?.items[0] || {},
        pageLinksWithRegionsAndSlugs,
        analyticsPageType: page?.analyticsPageType || '',
        sendCountry,
        receiveCountry,
        locale: region,
        regionCode,
        slug,
        organizationSchema,
      },
    };
  } catch (error) {
    logger.error({ error }, 'getServerSideProps failed in [[...slug]].tsx');
    return {
      notFound: true,
    };
  }
};
