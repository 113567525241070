import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  exchangeRateContainer: {
    background: colors.sendwaveGray,
    borderRadius: '10px',
  },
  exchangeRateHtmlRender: {
    padding: theme.spacing(1.8),
    fontWeight: 500,
    fontSize: 14,
    color: colors.sendwaveBlack,
  },
}));
