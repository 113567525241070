import { ReactNode } from 'react';

import { ModuleStores } from '@/state';
import { ModuleItem } from '@/utils';

import { Module } from './contentful';

const StateComponent = ({
  moduleName,
  overriddenProps,
}: {
  moduleName: string;
  overriddenProps: Record<string, unknown>;
}): null => {
  ModuleStores[moduleName](overriddenProps)();

  return null;
};

export const resolveState = (
  modules: Record<string, unknown>[],
  propsOverrides?: Record<string, Record<string, unknown>>,
): ReactNode[] =>
  modules.map(module => {
    const [moduleName, moduleData] = Object.entries(module)[0] || [];

    if (ModuleStores[moduleName]) {
      const moduleId = (moduleData as Module)?.sys?.id;

      if (moduleId && ModuleStores[moduleName]) {
        return (
          <StateComponent
            key={moduleId}
            moduleName={moduleName}
            overriddenProps={{
              ...(moduleData as Record<string, unknown>),
              ...(propsOverrides?.[moduleName] || {}),
            }}
          />
        );
      }
    }

    return null;
  });

export const hasModule = (modules: ModuleItem[], moduleId: string): boolean =>
  modules.some(module => Object.keys(module).includes(moduleId));
