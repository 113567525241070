import { useMemo } from 'react';

import { RichTextContentRenderer } from '@/components/rich-text-content-renderer';
import { usePricingStore } from '@/state';

import {
  StyledTableContainer,
  StyledUIContainer,
} from './cex-rate-table.styled-components';
import { CexRateTableProps } from './cex-rate-table.types';
import { ExchangeRateTable } from './exchange-rate-table';

export const CexRateTable = ({
  name,
  title,
  tableTitleLabel,
  conversionAmounts,
  tableSubtitleOne,
  tableSubtitleTwo,
  sendCurrency,
  receiveCurrency,
}: CexRateTableProps) => {
  const { pricing } = usePricingStore(state => state);

  const links = useMemo(() => {
    const sendCurrencySlug = sendCurrency?.pageSlug ?? ``;

    const receiveCurrencySlug = receiveCurrency?.pageSlug ?? ``;

    const corridorSlugParts = [
      'currency-converter',
      `${sendCurrency?.currencyCode.toLocaleLowerCase()}_${sendCurrency?.countryCode.toLocaleLowerCase()}-${receiveCurrency?.currencyCode.toLocaleLowerCase()}_${receiveCurrency?.countryCode.toLocaleLowerCase()}`,
    ];

    return { sendCurrencySlug, receiveCurrencySlug, corridorSlugParts };
  }, [
    sendCurrency?.pageSlug,
    sendCurrency?.currencyCode,
    sendCurrency?.countryCode,
    receiveCurrency?.pageSlug,
    receiveCurrency?.currencyCode,
    receiveCurrency?.countryCode,
  ]);

  const rate = pricing?.baseExchangeRate || 0;
  const exchangeRate = { send: rate, receive: rate ? 1 / rate : 0 };

  return (
    <StyledUIContainer
      component="section"
      data-testid="cex-rate-table"
      id={name || ''}
    >
      {title?.json && (
        <RichTextContentRenderer
          data-testid="cex-rate-table-title"
          json={title.json}
        />
      )}
      <StyledTableContainer>
        <ExchangeRateTable
          precision={2}
          sendCurrencyCode={sendCurrency?.currencyCode ?? ''}
          sendCurrencyName={sendCurrency?.currencyCode ?? ''}
          receiveCurrencyCode={receiveCurrency?.currencyCode ?? ''}
          receiveCurrencyName={receiveCurrency?.currencyCode ?? ''}
          exchangeRate={exchangeRate.send}
          tableTitleLabel={tableTitleLabel}
          conversionAmounts={conversionAmounts}
          tableSubtitleOne={tableSubtitleOne}
          tableSubtitleTwo={tableSubtitleTwo}
          links={links}
        />

        <ExchangeRateTable
          precision={4}
          sendCurrencyCode={receiveCurrency?.currencyCode ?? ''}
          sendCurrencyName={receiveCurrency?.currencyCode ?? ''}
          receiveCurrencyCode={sendCurrency?.currencyCode ?? ''}
          receiveCurrencyName={sendCurrency?.currencyCode ?? ''}
          exchangeRate={exchangeRate.receive}
          tableTitleLabel={tableTitleLabel}
          conversionAmounts={conversionAmounts}
          tableSubtitleOne={tableSubtitleOne}
          tableSubtitleTwo={tableSubtitleTwo}
          links={{
            sendCurrencySlug: links.receiveCurrencySlug,
            receiveCurrencySlug: links.sendCurrencySlug,
            corridorSlugParts: undefined,
          }}
        />
      </StyledTableContainer>
    </StyledUIContainer>
  );
};
