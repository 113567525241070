import { BLOCKS } from '@contentful/rich-text-types';
import { Grid } from '@mui/material';
import {
  APP_NAME,
  contentfulSchema,
  INTERACTION_NAME,
  pushToDataLayer,
} from '@wr/web-shared';
import {
  Container as UIContainer,
  Image,
  QrCode as UIQrCode,
  useImageForBreakpoint,
} from '@wr/web-ui';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { Asset } from '@/components/text-section/text-section.type';
import { AppContext } from '@/context';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Anchor } from '../anchor';
import { Button } from '../button';
import { useStyles } from './app-banner-section.styles';

const APP_BANNER_SECTION_QR_CODE_ID = 'app-banner-section-qr-code';

export function AppBannerSection({
  sys,
  __typename,
  name,
  content,
  imageCollection,
  imageMobile,
  linkDesktop,
  appStoreIconLink,
  playStoreIconLink,
}: contentfulSchema.AppBannerSectionFragment) {
  const { locale, analyticsPageType } = useContext(AppContext);
  const [fullUrl, setFullUrl] = useState('');

  useEffect(() => {
    setFullUrl(window.location.href);
  }, []);

  const dataLayerProps = {
    event: INTERACTION_NAME.DOWNLOAD_APP_CLICK,
    page_type: analyticsPageType,
    page_name: fullUrl,
    page_language: locale,
  };

  const onClickAppStoreLink = (): void => {
    pushToDataLayer({ ...dataLayerProps, app_name: APP_NAME.IOS });
  };

  const onClickPlayStoreLink = (): void => {
    pushToDataLayer({ ...dataLayerProps, app_name: APP_NAME.ANDROID });
  };
  const image = useImageForBreakpoint(
    imageCollection?.items[0] as Asset,
    imageCollection?.items[1] as Asset,
    imageCollection?.items[2] as Asset,
  );

  const classes = useStyles({
    imageMaxWidth: image?.width,
    mobileImageMaxWidth: imageMobile?.width,
  });

  const containerProps = buildContentfulSidekickAttributes(
    sys.id as string,
    __typename as string,
    name as string,
  );

  const appStoreIconLinkProps = {
    ...appStoreIconLink,
    'data-testid': 'app-banner-app-store-link',
    'onClick': onClickAppStoreLink,
    'children': appStoreIconLink?.image ? (
      <Image {...appStoreIconLink.image} fill={false} />
    ) : null,
  };

  const playStoreIconLinkProps = {
    ...playStoreIconLink,
    'data-testid': 'app-banner-play-store-link',
    'onClick': onClickPlayStoreLink,
    'children': playStoreIconLink?.image ? (
      <Image {...playStoreIconLink.image} fill={false} />
    ) : null,
  };

  const richTextContent = content?.json
    ? ({
        ...content,
        options: {
          [BLOCKS.PARAGRAPH]: {
            paragraph: true,
            variant: 'body2',
          },
          [BLOCKS.LIST_ITEM]: {
            variant: 'body2',
          },
        },
      } as RichTextProps)
    : null;

  return (
    <section
      id={name as string}
      {...containerProps}
      className={classes.section}
    >
      <UIContainer maxWidth="xl">
        <Grid
          data-testid="app-banner-section-container"
          container
          columnSpacing={8}
          rowSpacing={{ xs: 3, sm: 0 }}
          className={classes.gridContainer}
        >
          {(image || imageMobile) && (
            <Grid
              data-testid="app-banner-section-grid-image"
              item
              xs={12}
              sm={6}
              className={clsx(classes.gridItem, classes.gridImageItem)}
            >
              {image && (
                <div>
                  <UIQrCode
                    url={image.url}
                    width={image.width}
                    height={image.height}
                    title={image.title}
                    alt={image.alt}
                    description=""
                    classes={{
                      qrCodeImageContainer: classes.imageContainer,
                    }}
                    elementId={APP_BANNER_SECTION_QR_CODE_ID}
                  />
                </div>
              )}
              {imageMobile && (
                <div className={classes.imageContainer}>
                  <Image {...imageMobile} className="image-mobile" />
                </div>
              )}
            </Grid>
          )}
          {content && (
            <Grid
              data-testid="app-banner-section-content"
              item
              xs={12}
              sm={6}
              className={classes.gridItem}
            >
              <div className={classes.contentContainer}>
                {richTextContent && (
                  <RichTextContentRenderer {...richTextContent} />
                )}
                {linkDesktop && (
                  <Button
                    data-testid={'app-banner-section-button'}
                    variant="contained"
                    color="primary"
                    size="medium"
                    url={linkDesktop?.url}
                    className={classes.ctaButton}
                  >
                    {linkDesktop?.label}
                  </Button>
                )}
                <div className={classes.links}>
                  {appStoreIconLink && (
                    <Anchor
                      {...appStoreIconLinkProps}
                      key="app-banner-section-app-store-link"
                      data-testid="app-banner-section-app-store-link"
                      className={classes.iconLink}
                    />
                  )}
                  {playStoreIconLink && (
                    <Anchor
                      {...playStoreIconLinkProps}
                      key="app-banner-section-play-store-link"
                      data-testid="app-banner-section-play-store-link"
                      className={classes.iconLink}
                    />
                  )}
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </UIContainer>
    </section>
  );
}
