import { Typography } from '@mui/material';
import { Container as UIContainer } from '@wr/web-ui';

import { useStyles } from './hero-text-variation.styles';
import { HeroTextVariationProps } from './hero-text-variation.types';

export const HeroTextVariation = ({
  sys,
  name,
  heading,
  description,
  textColor,
  backgroundColor,
  backgroundImage,
}: HeroTextVariationProps) => {
  const classes = useStyles({
    textColor,
    backgroundColor,
    backgroundImage: backgroundImage?.url,
  });

  return (
    <section
      id={`hero-text-variation-${sys.id}`}
      className={classes.heroTextSection}
      data-testid={name}
    >
      <UIContainer className={classes.heroTextSectionContainer}>
        <div className={classes.heroWrapper}>
          <div className={classes.heroTextContent}>
            <Typography
              variant="h1"
              className={classes.heroTextHeading}
              data-testid="hero-text-variation-heading"
            >
              {heading}
            </Typography>
            {description && (
              <Typography
                variant="body2"
                className={classes.heroTextDescription}
                data-testid="hero-text-variation-description"
              >
                {description}
              </Typography>
            )}
          </div>
        </div>
      </UIContainer>
    </section>
  );
};
