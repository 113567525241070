import { Link } from '@mui/material';
import React from 'react';

import { useAppContext } from '@/context';
import { CountryData } from '@/services';
import { interpolate } from '@/utils';
import { CountryArticlesPrepositionsByCountryCode } from '@/utils/intl/locale.types';

import { useStyles } from './countries-grid-item.styles';

export const CountriesGridItem: React.FC<{
  receiveCountry: CountryData;
  sendCountryCode: string;
  sendCountries: CountryData[];
  countryArticlesAndPrepositions?: CountryArticlesPrepositionsByCountryCode;
}> = ({
  receiveCountry,
  sendCountryCode,
  sendCountries,
  countryArticlesAndPrepositions,
}) => {
  const { locale } = useAppContext();
  const classes = useStyles();

  const sendCountry = sendCountries.find(
    country => country.iso2 === sendCountryCode,
  );
  if (!sendCountry) return null;

  const sendCountryArticlesAndPrepositions =
    countryArticlesAndPrepositions?.[sendCountryCode.toLocaleUpperCase()];

  return (
    <Link
      key={`${receiveCountry.iso2}${sendCountry.iso2}`}
      href={`/${locale}/countries/${receiveCountry.name
        .replace(/\s+/g, '-')
        .toLowerCase()}/${sendCountry.iso2.toLowerCase()}`}
      data-testid={'countries-grid-card-link-' + sendCountry.iso2}
      className={classes.cardLink}
    >
      {interpolate(
        '{prepositionFrom}',
        sendCountryArticlesAndPrepositions || {
          prepositionFrom: sendCountry.name,
        },
      )}
    </Link>
  );
};
