import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  inputContainer: {
    'display': 'flex',
    'height': theme.spacing(10),
    'borderRadius': 10,
    'border': `2px solid ${colors.sendwaveYellow}`,
    'alignItems': 'center',

    '&:first-child': {
      marginBottom: '1rem',
    },
  },
  inputRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
  },
  selectContainer: {
    display: 'flex',
    background: colors.sendwaveYellow,
    height: 'inherit',
    borderTopRightRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
  },
  inputTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    color: colors.darkGray,
  },
  currency: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    width: '8em',
  },
  inputBaseProps: {
    padding: 0,
    fontWeight: 700,
    height: '2rem',
    color: colors.sendwaveBrown,
    fontSize: '2rem',
  },
  pricingCalculatorRoot: {
    lineHeight: 1.125,
    fontWeight: 700,
    color: colors.sendwaveBrown,
  },
  icon: {
    position: 'absolute',
    right: 10,
    fontSize: 28,
    top: 12,
    cursor: 'pointer',
  },
  title: {
    color: colors.sendwaveBrown,
    textAlign: 'center',
    padding: 20,
    paddingTop: 50,
  },
  loader: {
    marginTop: theme.spacing(0.75),
  },
  flag: {},
}));
