import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { important } from '@wr/web-ui';

import { colors } from '@/styles';

import { AccordionStyles } from './accordion.types';

export const useStyles = makeStyles<Theme, AccordionStyles>(theme => ({
  accordionSection: ({ backgroundColor }) => ({
    'width': '100%',
    'position': 'relative',
    'backgroundColor': backgroundColor || colors.white,
    'paddingTop': theme.spacing(8),
    'paddingBottom': theme.spacing(8),

    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  }),
  container: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  accordionHeading: {
    textAlign: 'center',
    marginBottom: theme.spacing(3.75),
  },
  accordionItem: {
    'boxShadow': 'none',
    'backgroundColor': colors.sendwaveGray,
    'borderBottom': '1px solid #D9D9D9',
    'borderRadius': important('0'),

    '&:last-child': {
      borderBottom: important('0'),
      marginBottom: 0,
    },

    '& .MuiAccordionDetails-root': {
      'padding': '16px',

      '& .MuiTypography-paragraph': {
        fontSize: '14px',
      },
    },

    '& .MuiTypography-root': {
      marginBottom: '0',
    },
  },
  accordionItemSummary: {
    'padding': 0,

    '& .MuiSvgIcon-root': {
      color: colors.sendwaveBlack,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      paddingRight: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'center',
    },
  },
  accordionItemSummaryTitle: {
    color: colors.sendwaveBlack,
    fontSize: theme.spacing(2.5),
    paddingBottom: theme.spacing(0),
  },
}));
