import { setCookie } from 'cookies-next';

import { INITIAL_SEND_AMOUNT, ONE_WEEK_EXPIRATION } from '@/constants';
import { CountryData } from '@/services';

import { CALCULATOR_INPUT_VALUE_WITH_TWO_DIGITS } from './core/core.constants';
import { SendReceiveCountry } from './exchange-calculator.types';

export const getInputValue = (value: string): string | null => {
  const isValueEndsWithSingleDot =
    value.split('.').length - 1 === 1 && value.endsWith('.');

  if (CALCULATOR_INPUT_VALUE_WITH_TWO_DIGITS.test(value)) {
    return value;
  }
  if (isValueEndsWithSingleDot) {
    const replacedValue = value.replace(/^0+/, '');

    if (replacedValue.length === 1 && replacedValue === '.') {
      return '0.';
    }

    return replacedValue;
  }

  return null;
};

export const getUrlOverride = (
  sendCountries: SendReceiveCountry,
  initSendCountry: CountryData,
  extractSendAmountFromUrl: () => number | null,
): {
  sendCountryCode: string | null;
  sendAmount: number;
  isAmountFromUrl: boolean;
} => {
  const path = window.location.pathname;

  // Match URLs like /en-gb/currency-converter/1000-eur_be-kes_ke
  const amountWithCurrencyMatch = path.match(
    /^\/([a-z]{2}(?:-[a-z]{2})?)\/currency-converter\/(\d+)-([a-z]{3})_([a-z]{2})-[a-z]{3}_[a-z]{2}$/i,
  );

  if (amountWithCurrencyMatch) {
    const sendAmount = extractSendAmountFromUrl() || INITIAL_SEND_AMOUNT;
    const sendCurrency = amountWithCurrencyMatch[3].toUpperCase();
    const sendCountryCode = amountWithCurrencyMatch[4].toUpperCase();

    const matchedCountry = Object.values(sendCountries).find(
      country =>
        country.currency === sendCurrency &&
        country.iso2.toLowerCase() === sendCountryCode.toLowerCase(),
    );

    setCookie('urlSendAmount', sendAmount, { maxAge: ONE_WEEK_EXPIRATION });

    return {
      sendCountryCode: matchedCountry ? matchedCountry.iso2 : null,
      sendAmount,
      isAmountFromUrl: true,
    };
  }

  // Match URLs like /en-us/currency-converter/cad_ca-kes_ke (CAD is the send currency)
  const currencyWithCountryMatch = path.match(
    /^\/[a-z]{2}-[a-z]{2}\/currency-converter\/([a-z]{3})_([a-z]{2})-[a-z]{3}_[a-z]{2}$/i,
  );
  if (currencyWithCountryMatch) {
    const sendCurrency = currencyWithCountryMatch[1].toUpperCase();
    const sendCountryCode = currencyWithCountryMatch[2].toLowerCase();

    if (!sendCountries) {
      return {
        sendCountryCode: null,
        sendAmount: INITIAL_SEND_AMOUNT,
        isAmountFromUrl: false,
      };
    }

    const matchedCountry = Object.values(sendCountries).find(
      country =>
        country.currency === sendCurrency &&
        country.iso2.toLowerCase() === sendCountryCode,
    );

    return {
      sendCountryCode: matchedCountry ? matchedCountry.iso2 : null,
      sendAmount: INITIAL_SEND_AMOUNT,
      isAmountFromUrl: false,
    };
  }

  // Match URLs like /en-us/currency-converter/gbp-ngn (GBP is the send currency)
  const currencyOnlyMatch = path.match(
    /^\/[a-z]{2}-[a-z]{2}\/currency-converter\/([a-z]{3})-[a-z]{3}$/i,
  );
  if (currencyOnlyMatch) {
    if (!sendCountries) {
      return {
        sendCountryCode: null,
        sendAmount: INITIAL_SEND_AMOUNT,
        isAmountFromUrl: false,
      };
    }

    const matchedCountry = Object.values(sendCountries).find(
      country =>
        country.iso2.toLowerCase() === initSendCountry.iso2.toLowerCase(),
    );

    return {
      sendCountryCode: matchedCountry ? matchedCountry.iso2 : null,
      sendAmount: INITIAL_SEND_AMOUNT,
      isAmountFromUrl: false,
    };
  }

  // Match URLs like /en-us/currency-converter/eur_be-kes_ke (EUR is the send currency, BE is the send country)
  const countryWithCurrencyMatch = path.match(
    /^\/[a-z]{2}-[a-z]{2}\/currency-converter\/([a-z]{3})_[a-z]{2}-[a-z]{3}_[a-z]{2}$/i,
  );
  if (countryWithCurrencyMatch) {
    const sendCurrency = countryWithCurrencyMatch[1].toUpperCase();

    if (!sendCountries) {
      return {
        sendCountryCode: null,
        sendAmount: INITIAL_SEND_AMOUNT,
        isAmountFromUrl: false,
      };
    }

    const matchedCountry = Object.values(sendCountries).find(
      country => country.currency === sendCurrency,
    );

    return {
      sendCountryCode: matchedCountry ? matchedCountry.iso2 : null,
      sendAmount: INITIAL_SEND_AMOUNT,
      isAmountFromUrl: false,
    };
  }

  // Match URLs like /en-us/countries/be
  const countryPageMatch = path.match(
    /^\/[a-z]{2}\/countries\/[a-z]+\/([a-z]{2})/i,
  );
  if (countryPageMatch) {
    return {
      sendCountryCode: countryPageMatch[1].toUpperCase(),
      sendAmount: INITIAL_SEND_AMOUNT,
      isAmountFromUrl: false,
    };
  }

  // Match locale like /en-us
  const localeMatch = path.match(/^\/([a-z]{2}-[a-z]{2})/);
  if (localeMatch) {
    return {
      sendCountryCode: localeMatch[1].split('-')[1].toUpperCase(),
      sendAmount: INITIAL_SEND_AMOUNT,
      isAmountFromUrl: false,
    };
  }

  // Match old country page like /countries/currency/be
  const oldCountryPageMatch = path.match(/^\/countries\/([a-z]+)\/([a-z]{2})/i);
  if (oldCountryPageMatch) {
    return {
      sendCountryCode: oldCountryPageMatch[2].toUpperCase(),
      sendAmount: INITIAL_SEND_AMOUNT,
      isAmountFromUrl: false,
    };
  }

  return {
    sendCountryCode: null,
    sendAmount: INITIAL_SEND_AMOUNT,
    isAmountFromUrl: false,
  };
};
