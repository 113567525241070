import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from '@mui/material';
import { important, ThemeBreakpoints } from '@wr/web-ui';

import { colors } from '@/styles';

export const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 3),
  backgroundColor: colors.sendwaveGray,
  borderRadius: important(theme.spacing(2)),
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  'boxShadow': 'none',
  'padding': 0,
  'backgroundColor': colors.sendwaveGray,
  'borderBottom': '0',
  'borderRadius': important('0'),

  '& .MuiAccordionDetails-root': {
    padding: '16px',
  },

  '& .MuiPaper-elevation': {
    padding: '0',
  },

  '& .MuiSvgIcon-root': {
    color: colors.sendwaveBlack,
  },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-180deg)',
  },
  'marginBottom': important(theme.spacing(2)),
}));

export const StyledSubAccordion = styled(Accordion)(({ theme }) => ({
  'boxShadow': 'none',
  'padding': 0,
  'backgroundColor': colors.sendwaveGray,
  'borderBottom': '0',
  'borderRadius': important('0'),

  '& .MuiAccordionDetails-root': {
    padding: '16px',
  },

  '& .MuiPaper-elevation': {
    padding: '0',
  },

  '& .MuiSvgIcon-root': {
    color: colors.sendwaveBlack,
  },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-180deg)',
  },
  'marginBottom': important(theme.spacing(2)),
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  'padding': 0,

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-180deg)',
  },
  '& .MuiSvgIcon-root': {
    transform: 'rotate(180deg)',
    color: colors.sendwaveBlack,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    paddingRight: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
}));

export const StyledAccordionSummaryTitle = styled(Typography)(({ theme }) => ({
  color: colors.sendwaveBlack,

  [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
    fontSize: theme.spacing(3.5),
  },
  [theme.breakpoints.down(ThemeBreakpoints.xs)]: {
    fontSize: theme.spacing(2.5),
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  color: colors.grey,
  backgroundColor: colors.white,
  padding: '14px 16px',
  borderRadius: '8px',
  fontSize: '16px',
  marginBottom: theme.spacing(2),
}));
