import { Box, Typography } from '@mui/material';
import { CountryFlag } from '@wr/web-ui';
import React, { useEffect, useState } from 'react';

import { Anchor } from '@/components/anchor';
import { CountryData } from '@/services';
import { getCorridorsBySendCountry } from '@/services/rest';

import { useStyles } from './cex-currency-conversion-rates.styles';
import { CexCurrencyConversionRatesProps } from './cex-currency-conversion-rates.types';
import { CexCurrencyExchangeRate } from './cex-currency-exchange-rate';

export const CexCurrencyConversionRates: React.FC<CexCurrencyConversionRatesProps> = ({
  sendCurrency,
}) => {
  const classes = useStyles();
  const [sendCountryData, setSendCountryData] = useState<Record<
    string,
    CountryData
  > | null>(null);
  const [isSendCurrency, setIsSendCurrency] = useState(false);

  useEffect(() => {
    if (sendCurrency?.countryCode) {
      const countryCode = sendCurrency.countryCode.toLowerCase();
      const isMatchingCountry =
        sendCountryData &&
        Object.prototype.hasOwnProperty.call(sendCountryData, countryCode);

      setIsSendCurrency(!!isMatchingCountry);
    }
  }, [sendCurrency, sendCountryData]);

  useEffect(() => {
    const fetchCorridorsBySendCountry = async () => {
      const data = await getCorridorsBySendCountry();
      setSendCountryData(data);
    };

    fetchCorridorsBySendCountry();
  }, []);

  return (
    <Box className={classes.container}>
      <div className={classes.heading}>
        {isSendCurrency && (
          <Typography variant="body1">
            <b>{`From ${sendCurrency.currencyCode}`}</b>
          </Typography>
        )}
      </div>

      {sendCurrency.topCurrencyPairs?.items.map(currency => (
        <Anchor
          className={classes.corridorLink}
          key={currency?.currencyCode}
          url={currency?.pageSlug}
        >
          <div className={classes.corridorRow}>
            <div className={classes.flexWrapper}>
              <CountryFlag code={currency?.countryCode ?? ''} />
              <Typography variant="body1">{currency?.currencyName}</Typography>
            </div>
            <div className={classes.flexWrapper}>
              <Typography variant="body1">
                <b>{`${sendCurrency.currencyCode}/${currency?.currencyCode}`}</b>
              </Typography>
              {isSendCurrency && (
                <Typography paragraph>
                  <CexCurrencyExchangeRate
                    sendCurrency={{
                      countryCode: sendCurrency?.countryCode ?? '',
                      currencyCode: sendCurrency?.currencyCode ?? '',
                    }}
                    receiveCurrency={{
                      countryCode: currency?.countryCode ?? '',
                      currencyCode: currency?.currencyCode ?? '',
                    }}
                  />
                </Typography>
              )}
            </div>
          </div>
        </Anchor>
      ))}
    </Box>
  );
};
