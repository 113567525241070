import { Grid, Typography } from '@mui/material';
import React from 'react';

import { Container as UIContainer } from '..';
import { Image } from '../image';
import useStyles from './image-grid.styles';
import { ImageGridProps } from './image-grid.types';

export function ImageGrid<RichText>({
  name,
  heading,
  content,
  gridColumnSize,
  gridItems,
  RichTextComponent,
}: ImageGridProps<RichText>): React.ReactElement | null {
  const classes = useStyles();

  return (
    <section
      id={name}
      data-testid={'image-grid-section'}
      className={classes.section}
    >
      <UIContainer data-testid="image-grid-container">
        {heading && (
          <Typography
            variant="h2"
            className={classes.heading}
            data-testid="image-grid-heading"
          >
            {heading}
          </Typography>
        )}
        {content && (
          <RichTextComponent data-testid="image-grid-content" {...content} />
        )}
        <Grid
          data-testid="image-grid-container"
          justifyContent={'center'}
          alignContent={'center'}
          container
          spacing={5}
        >
          {gridItems.map(gridItem => {
            return gridItem.image ? (
              <Grid
                key={gridItem.name}
                data-testid="image-grid-item"
                justifyContent={'center'}
                alignContent={'center'}
                item
                xs={12}
                md={gridColumnSize}
                className={classes.gridItem}
              >
                <Image {...gridItem.image} />
              </Grid>
            ) : (
              <></>
            );
          })}
        </Grid>
      </UIContainer>
    </section>
  );
}
