import { EVENT, pushToDataLayer } from '@wr/web-shared';
import { AppContextProviderShared, getCountryCodeFromLocale } from '@wr/web-ui';
import React, { FC, useEffect, useState } from 'react';

import { AppContextProvider } from '@/context';
import { parseCountryDataPartial } from '@/services/rest';
import { useCountriesStore } from '@/state';
import { resolveComponents } from '@/utils/component-resolver';
import { resolveState } from '@/utils/state-resolver';

import { PageMetadataComponent } from '../page-metadata/page-metadata.component';
import { Theme } from '../theme';
import { Layout } from './layout.component';
import { GenericPageLayoutProps } from './layout.types';

export const GenericPageLayout: FC<GenericPageLayoutProps> = props => {
  const {
    __typename,
    modules,
    name,
    isWebView,
    preview,
    sys,
    title,
    description,
    breadcrumbs,
    noIndex,
    header,
    originUri,
    footer,
    countries,
    regions,
    messages,
    pageLinksWithRegionsAndSlugs,
    analyticsPageType,
    sendCountry,
    receiveCountry,
    locale,
    router,
    regionCode,
    slug,
    organizationSchema,
    currencies,
    corridors,
    children,
  } = props;
  const hydrate = useCountriesStore(state => state.hydrate);
  React.useEffect(() => {
    if (countries) hydrate(countries);
  }, [hydrate, countries]);

  const resolvedComponents = children
    ? children
    : resolveComponents(modules || [], {}, header);

  const resolvedState = resolveState(modules || [], {
    FaqSection: { receiveCountry: receiveCountry?.iso2 },
  });

  const [fullUrl, setFullUrl] = useState('');
  useEffect(() => {
    setFullUrl(window.location.href);
  }, []);

  useEffect(() => {
    pushToDataLayer({
      event: EVENT.PAGE_LOADED,
      page_type: analyticsPageType,
      page_name: fullUrl,
      page_language: locale,
      sender_country: sendCountry?.name,
      sender_country_iso: sendCountry?.iso2.toUpperCase(),
    });
  }, [analyticsPageType, fullUrl, locale, sendCountry]);

  return (
    <AppContextProviderShared
      isWebView={isWebView}
      originUri={originUri}
      regions={regions as string[]}
      messages={messages}
      sendCountry={parseCountryDataPartial(
        router.query.from
          ? countries.find(country => country.iso2 === router.query.from)
          : sendCountry,
      )}
      receiveCountry={parseCountryDataPartial(
        router.query.to
          ? countries.find(country => country.iso2 === router.query.to)
          : receiveCountry,
      )}
      locale={locale}
      analyticsPageType={analyticsPageType}
    >
      <AppContextProvider
        currencies={currencies}
        corridors={corridors}
        placeholder="TODO: Add SW specific props here"
      >
        <PageMetadataComponent
          title={title}
          description={description}
          originUri={originUri}
          pathname={slug ? `/${slug}` : ''}
          noIndex={noIndex}
          pageLinksWithRegionsAndSlugs={pageLinksWithRegionsAndSlugs}
          regions={regions}
          analyticsPageType={analyticsPageType}
          regionCode={regionCode}
          organizationSchema={organizationSchema}
          modules={modules}
        />
        <Theme>
          {resolvedState}
          <Layout
            __typename={__typename}
            name={name}
            preview={preview}
            sys={sys}
            footer={{ ...footer }}
            header={isWebView ? null : header}
            breadcrumbs={breadcrumbs}
            languageFromCookie={getCountryCodeFromLocale(locale)}
          >
            {resolvedComponents}
          </Layout>
        </Theme>
      </AppContextProvider>
    </AppContextProviderShared>
  );
};
