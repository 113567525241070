import { BLOCKS } from '@contentful/rich-text-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '../../rich-text-content-renderer';
import { NestedAccordionDetailsProps } from '../nested-accordion.types';

export const NestedAccordionDetails = ({
  subtitles,
  classes,
  handleToggleAccordion,
  handleSubPanelIcon,
  subExpandedPanel,
  setSubExpandedPanel,
}: NestedAccordionDetailsProps) => {
  return (
    <AccordionDetails
      className={classes.accordionDetails}
      data-testid="faq-accordion-details-section"
    >
      {subtitles?.items?.map((subtitle, index) => {
        const subPanelIndex = `sub_panel_${index}`;

        let contentProps = null;
        if (subtitle?.content?.json) {
          contentProps = {
            ...subtitle.content,
            options: {
              [BLOCKS.PARAGRAPH]: {
                paragraph: true,
                variant: 'body2',
                fontSize: '0.9rem',
              },
              [BLOCKS.LIST_ITEM]: {
                variant: 'body2',
                fontSize: '0.9rem',
              },
            },
          } as RichTextProps;
        }

        return (
          <Accordion
            className={classes.subAccordion}
            data-testid={`faq-accordion-details-subtitle-${subtitle?.sys?.id}`}
            expanded={subExpandedPanel === subPanelIndex}
            onChange={() =>
              handleToggleAccordion(setSubExpandedPanel, subPanelIndex)
            }
            key={subtitle?.sys?.id}
          >
            <AccordionSummary
              className={classes.subAccordionSummary}
              expandIcon={handleSubPanelIcon(subPanelIndex)}
            >
              <Typography
                variant="h6"
                className={`${classes.accordionSummaryTitle} ${classes.subAccordionSummaryTitle}`}
              >
                {subtitle?.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {subtitle?.description && (
                <Typography
                  className={classes.accordionSummaryDescription}
                  paragraph
                >
                  {subtitle.description}
                </Typography>
              )}
              {contentProps && (
                <div className={classes.accordionSummaryDescription}>
                  <RichTextContentRenderer {...contentProps} />
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </AccordionDetails>
  );
};
