import {
  GetLinksWithoutRedirects,
  RedirectsQueryResult,
} from './redirect.types';

export const getLinksWithoutRedirects = (
  linksSlugsAndTitles: GetLinksWithoutRedirects,
  pageRedirects: RedirectsQueryResult,
): GetLinksWithoutRedirects => {
  return linksSlugsAndTitles?.filter(item => {
    if (item?.slug === null) return null;

    const redirect = pageRedirects?.find(
      item2 => item2?.source === `/${item.slug}`,
    );
    return !redirect;
  });
};
