import { BLOCKS } from '@contentful/rich-text-types';
import { ImageGrid as UIImageGrid } from '@wr/web-ui';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';

import { ImageGridProps } from './image-grid.types';

export const ImageGrid: React.FC<ImageGridProps> = ({
  name,
  heading,
  content,
  gridColumnSize = 3,
  gridItems,
}) => {
  return (
    <UIImageGrid<RichTextProps | null>
      name={name || ''}
      heading={heading || ''}
      content={
        content?.json
          ? ({
              ...content,
              options: {
                [BLOCKS.PARAGRAPH]: {
                  paragraph: true,
                  variant: 'body2',
                },
                [BLOCKS.LIST_ITEM]: {
                  variant: 'body2',
                },
              },
            } as RichTextProps)
          : null
      }
      gridItems={
        gridItems?.items.map(item => ({
          name: item?.name || '',
          image: item?.image || null,
        })) || []
      }
      gridColumnSize={gridColumnSize as number}
      RichTextComponent={RichTextContentRenderer}
    />
  );
};
