import { Grid } from '@mui/material';
import { contentfulSchema } from '@wr/web-shared';
import { Container as UIContainer } from '@wr/web-ui';
import React from 'react';

import { CountryData } from '@/services';
import { CountryArticlesPrepositionsByCountryCode } from '@/utils/intl/locale.types';

import { useStyles } from './countries-grid.styles';
import { CountriesGridCard } from './countries-grid-card/countries-grid-card.component';

export type CountriesGridProps = contentfulSchema.CountriesGridFragment & {
  countries: CountryData[];
  countryArticlesAndPrepositions?: CountryArticlesPrepositionsByCountryCode;
};

export const CountriesGrid: React.FC<CountriesGridProps> = ({
  name,
  moneyTransfers,
  countries,
  countryArticlesAndPrepositions,
}) => {
  const classes = useStyles();

  const sendCountries = countries?.filter(country => country.send);

  return (
    <section data-testid="countries-grid" className={classes.section}>
      <UIContainer className={classes.container}>
        <Grid
          data-testid={`countries-grid-${name}`}
          container
          columnSpacing={3}
          rowSpacing={3}
          className={classes.gridContainer}
        >
          {countries
            ?.filter(country => country.receive)
            ?.map(country => {
              return (
                <Grid
                  key={country.iso2}
                  data-testid="countries-grid-grid-item"
                  item
                  xs={12}
                  sm={6}
                  lg={3}
                  className={classes.gridItem}
                >
                  <CountriesGridCard
                    key={country.iso2}
                    receiveCountry={country}
                    sendCountries={sendCountries}
                    moneyTransfers={moneyTransfers || 'Send money'}
                    countryArticlesAndPrepositions={
                      countryArticlesAndPrepositions
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
      </UIContainer>
    </section>
  );
};
