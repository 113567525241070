import { Link } from '@mui/material';
import { CountryFlag } from '@wr/web-ui';
import React from 'react';

import { useAppContext } from '@/context';
import { CountryData } from '@/services';
import { interpolate } from '@/utils';
import { CountryArticlesPrepositionsByCountryCode } from '@/utils/intl/locale.types';

import { CountriesGridItem } from '../countries-grid-item/countries-grid-item.component';
import { useStyles } from './countries-grid-card.styles';

export const CountriesGridCard: React.FC<{
  receiveCountry: CountryData;
  sendCountries?: CountryData[];
  moneyTransfers?: string;
  countryArticlesAndPrepositions?: CountryArticlesPrepositionsByCountryCode;
}> = ({
  receiveCountry,
  sendCountries,
  moneyTransfers,
  countryArticlesAndPrepositions,
}) => {
  const { locale } = useAppContext();
  const classes = useStyles();
  const receiveCountryArticlesAndPrepositions =
    countryArticlesAndPrepositions?.[receiveCountry.iso2.toUpperCase()];

  if (!receiveCountry || !sendCountries) return null;

  return (
    <div
      data-testid={`info-cards-item-${receiveCountry.iso2}`}
      className={classes.card}
    >
      <div className={classes.textContainer}>
        <CountryFlag code={receiveCountry.iso2} type="rectangle" />
        <Link
          href={`/${locale}/countries/${receiveCountry.name
            .replace(/\s+/g, '-')
            .toLowerCase()}`}
          data-testid={'countries-grid-card-link-' + receiveCountry.iso2}
          className={classes.cardLinkTitle}
        >
          {moneyTransfers}{' '}
          {interpolate(
            '{prepositionTo}',
            receiveCountryArticlesAndPrepositions || {
              prepositionTo: receiveCountry.name,
            },
          )}
        </Link>
        {receiveCountry?.validSendCountriesIso2?.map(sendCountryCode => (
          <CountriesGridItem
            key={sendCountryCode}
            receiveCountry={receiveCountry}
            sendCountryCode={sendCountryCode}
            sendCountries={sendCountries}
            countryArticlesAndPrepositions={countryArticlesAndPrepositions}
          />
        ))}
      </div>
    </div>
  );
};
