import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(theme => ({
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: colors.veryDarkGrayishBlue,
    fontSize: '0.8125rem',
    borderRadius: 0,
  },
  popperDisablePortal: {
    position: 'relative !important' as 'relative',
    transform: 'none !important' as 'none',
  },
  listbox: {
    maxHeight: '70vh',
    [theme.breakpoints.up('md')]: {
      maxHeight: '40vh',
    },
  },
  input: {
    '&::placeholder': {
      fontSize: '1rem',
    },
  },
  notchedOutline: {
    border: 'none',
  },
  options: {
    '&&': {
      padding: theme.spacing(1.5, 2.375),
    },
  },
  textField: {
    background: '#F7F7F7',
    borderTop: '1px solid #E6EAEE',
  },
  listboxPadding: {
    padding: 0,
  },
}));

export default useStyles;
