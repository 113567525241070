import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { InfoCardStyleProps } from './card.types';

function cardContentAlignment(contentAlignment?: string | null) {
  switch (contentAlignment) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return 'flex-start';
  }
}

export const useStyles = makeStyles<Theme, InfoCardStyleProps>(theme => ({
  card: ({ hasButton }) => ({
    width: '100%',
    height: '100%',
    border: theme.customComponents.card.border,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: hasButton ? 'space-between' : 'flex-start',
  }),
  textContainer: ({ textColor, contentAlignment }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    color: textColor || theme.customComponents.card.textContainer.color,
    alignItems: cardContentAlignment(contentAlignment),
  }),
  cardIconContainer: {
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  cardIcon: {
    width: 'auto',
    height: 'auto',
    maxWidth: theme.spacing(5),
    maxHeight: theme.spacing(5),
  },
  cardButtonContainer: {
    padding: theme.spacing(3),
  },
  cardButton: {
    backgroundColor: theme.customComponents.card.ctaButton.backgroundColor,
    color: theme.customComponents.card.ctaButton.color,
    border: theme.customComponents.card.ctaButton.border,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
