import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  card: {
    backgroundColor: colors.white,
    borderRadius: '8px',
    width: '350px',
    height: '410px',
    textAlign: 'center',
    margin: theme.spacing(0, 1),
    padding: theme.spacing(3, 2),
    overflow: 'auto',
  },
  image: {
    marginBottom: '12px',
    maxWidth: '80px',
    maxHeight: '80px',
  },
  title: {
    marginBottom: '12px',
    alignSelf: 'stretch',
    color: colors.sendwaveBlack,
    fontSize: '20px',
  },
  ratings: {
    marginBottom: '12px',
    color: colors.sendwaveTeal,
  },
  description: {
    fontSize: '18px',
    color: colors.grey,
  },
}));
