import { contentfulSchema } from '@wr/web-shared';

import { contentfulClient, logger } from '@/utils';

export const searchFaqItems = async (
  searchTerm: string,
  locale: string,
  preview?: boolean,
): Promise<contentfulSchema.SearchFaqItemsQuery> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.SearchFaqItemsQuery>({
      query: contentfulSchema.SearchFaqItems,
      variables: {
        searchTerm,
        locale,
        preview,
      },
    });

    return data;
  } catch (error) {
    logger.error(error, `SearchFaqItems request failed`);

    throw {
      name: 'SearchFaqItemsQuery',
      message: error,
    };
  }
};

export const getFaqItems = async (
  locale: string,
  preview?: boolean,
): Promise<
  NonNullable<contentfulSchema.GetFaqItemsQuery['faqItemCollection']>['items']
> => {
  try {
    const result: NonNullable<
      contentfulSchema.GetFaqItemsQuery['faqItemCollection']
    >['items'] = [];

    // Batching queries to make sure we satisfy the contentful query complexity limits
    // https://www.contentful.com/developers/docs/references/graphql/#/introduction/query-complexity-limits
    const batchSize = 100;
    let hasMore = true;
    let count = 0;

    while (hasMore) {
      const {
        data,
      } = await contentfulClient.query<contentfulSchema.GetFaqItemsQuery>({
        query: contentfulSchema.GetFaqItems,
        variables: {
          locale,
          preview,
          limit: batchSize,
          skip: count * batchSize,
        },
      });

      if (data?.faqItemCollection?.items)
        result.push(...data.faqItemCollection.items);

      hasMore =
        !!data?.faqItemCollection?.items &&
        data?.faqItemCollection?.items.length >= batchSize;
      count++;
    }

    return result;
  } catch (error) {
    logger.error(error, `GetFaqItems request failed`);

    return [];
  }
};
