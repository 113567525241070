import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Image } from '@wr/web-ui';
import React from 'react';

import { logger } from '@/utils';

import { useStyles } from './payment-methods.styles';
import { PaymentSegmentMethodsData } from './payment-methods.types';

export const PaymentMethods: React.FC<PaymentSegmentMethodsData> = payoutMethods => {
  const classes = useStyles();

  const {
    images,
    isLoading,
    limitReached,
    handleChange,
    selectePaymentSegmentMethod,
    paymentMethodSegments,

    isRateLoading,
    receiveCountry,
    sendCountry,
    isPricingSegmentsLoading,
    countriesLoading,
    pricingSegments,
    setSelectedSegment,
    setPaymentSegments,
    fetchPricingSegmentMethods,
    locale,
  } = payoutMethods;

  React.useEffect(() => {
    if (isPricingSegmentsLoading || countriesLoading) return;

    const payoutMethodsAndPrices = pricingSegments?.payoutMethodsAndPrices;
    const isBestPricedPayoutMethod = payoutMethodsAndPrices?.find(
      item => item.isBestPricedPayoutMethod,
    );

    setSelectedSegment(isBestPricedPayoutMethod);
    setPaymentSegments(payoutMethodsAndPrices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countriesLoading,
    isPricingSegmentsLoading,
    pricingSegments?.payoutMethodsAndPrices,
  ]);

  React.useEffect(() => {
    (() => {
      try {
        if (
          !sendCountry ||
          !receiveCountry ||
          !sendCountry?.currency ||
          !receiveCountry?.currency ||
          isPricingSegmentsLoading ||
          countriesLoading ||
          isRateLoading
        )
          return;

        const params = {
          sendCountryIso2: sendCountry.iso2,
          sendCurrency: sendCountry.currency,
          receiveCountryIso2: receiveCountry.iso2,
          receiveCurrency: receiveCountry.currency,
        };

        fetchPricingSegmentMethods(params, locale);
      } catch (error) {
        logger.error(error, `Failed to fetch payment methods`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendCountry, receiveCountry, fetchPricingSegmentMethods, locale]);

  if (!paymentMethodSegments?.length || isLoading) return <></>;

  return (
    <>
      {!limitReached && (
        <ToggleButtonGroup
          fullWidth
          color="primary"
          value={selectePaymentSegmentMethod}
          exclusive
          onChange={handleChange}
          className={classes.buttonGroup}
          aria-label="Platform"
        >
          {paymentMethodSegments?.flatMap((item, index) => (
            <ToggleButton
              key={item.bestPricedSegmentName}
              value={item}
              className={classes.toggleButton}
            >
              <span className={classes.toggleContent}>
                {images?.length && (
                  <Image
                    {...images[index]}
                    className={classes.accordionSummaryAvatar}
                  />
                )}
                {item.label}
              </span>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </>
  );
};
