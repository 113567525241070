import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  cardLink: {
    color: theme.palette.text.secondary,
    textDecorationColor: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(2),
    textTransform: 'capitalize',
  },
}));
