import { Typography } from '@mui/material';
import { CountryFlag } from '@wr/web-ui';
import React from 'react';

import useStyles from './country-row.styles';
import { CountryRowProps } from './country-row.types';

export function CountryRow({
  countryCode,
  countryName,
  currencyCode,
}: CountryRowProps): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.flexContainer}>
      <CountryFlag className={classes.flag} code={countryCode} />
      <Typography className={classes.countryName}>
        {countryName}
        <span className={classes.currencyCode}>{currencyCode}</span>
      </Typography>
    </div>
  );
}
