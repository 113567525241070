import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  section: {
    padding: theme.spacing(4, 0),
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    backgroundColor: theme.palette.background.paper,
  },
}));
