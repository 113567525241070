import { DEFAULT_LANGUAGE, setCountryFromCookie } from '@wr/web-shared';
import {
  Footer as UIFooter,
  getSendCountryCodeFromLocale,
  Image,
  ImageProps,
} from '@wr/web-ui';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { AppContext } from '@/context';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Anchor, AnchorProps } from '../anchor';
import { Breadcrumbs } from '../breadcrumbs';
import { useStyles } from './footer.styles';
import { FooterProps } from './footer.types';

export const Footer: React.FC<FooterProps> = ({
  __typename,
  name,
  sys,
  breadcrumbs,
  legalMessage,
  copyrightText,
  socialIconsLinksCollection,
  navigationCollection,
  footerLogo,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const { locale, defaultLocale } = router;
  const { messages, regions } = useContext(AppContext);

  const [region, setRegion] = useState(locale);

  useEffect(() => {
    setRegion(locale);
  }, [locale]);

  if (!locale || !region) {
    return null;
  }

  const mappedNavigation =
    navigationCollection?.items.map(item => ({
      title: item?.title || '',
      links: item?.linksCollection?.items || [],
    })) || [];

  const onChange = (newLocale: string) => {
    const sendCountryCode = getSendCountryCodeFromLocale(newLocale) || '';

    if (sendCountryCode) {
      setCountryFromCookie(sendCountryCode.toUpperCase());
    }
  };

  const href = router.asPath.split('?')[0];
  return (
    <UIFooter<AnchorProps | null, RichTextProps | null>
      AnchorComponent={link => {
        return (
          <Anchor
            {...link}
            color="white"
            underline="none"
            variant={undefined}
            align={undefined}
            data-testid={`footer-link-${link.label}`}
          >
            {link.image ? <Image {...link.image} /> : link.label}
          </Anchor>
        );
      }}
      breadcrumbs={
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          color="white"
          linkColor="white"
          className={classes.breadcrumbs}
        />
      }
      copyrightText={copyrightText || ''}
      footerLogo={footerLogo as ImageProps}
      legalMessage={{
        content: legalMessage?.content?.json ? legalMessage.content : null,
        containerProps: buildContentfulSidekickAttributes(
          legalMessage?.sys?.id,
          legalMessage?.__typename,
          legalMessage?.name,
        ),
      }}
      navigation={mappedNavigation}
      socialLinks={
        socialIconsLinksCollection?.items?.map(item => ({
          ...item,
          isInternal: false,
          openInNewTab: true,
        })) || []
      }
      regionDropdownProps={{
        value: region,
        locale: locale || DEFAULT_LANGUAGE,
        locales: regions?.filter(l => l !== defaultLocale),
        internationalLabel: messages?.international || '',
        countriesLabel: messages?.countries || '',
        position: 'top',
        menuProps: {
          anchorOrigin: {
            vertical: -5,
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        },
        onChange,
        LinkComponent: ({ ...props }) => <NextLink {...props} href={href} />,
      }}
      chooseYourRegionLabel={messages?.chooseYourRegion || ''}
      RichTextComponent={RichTextContentRenderer}
      containerProps={buildContentfulSidekickAttributes(
        sys?.id,
        __typename,
        name || undefined,
      )}
    />
  );
};
