import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getBackground, important, ThemeBreakpoints } from '@wr/web-ui';

import { colors } from '@/styles';

import { FaqHeroProps } from './faq-hero.types';

type HeroStyleProps = Pick<
  FaqHeroProps,
  'primaryBackgroundColor' | 'secondaryBackgroundColor' | 'textColor'
>;

export const useStyles = makeStyles<Theme, HeroStyleProps>(
  ({ spacing, breakpoints }) => ({
    heroSection: ({ primaryBackgroundColor, secondaryBackgroundColor }) => ({
      width: '100%',
      position: 'relative',
      background: getBackground(
        primaryBackgroundColor,
        secondaryBackgroundColor,
      ),
      overflow: 'hidden',

      [breakpoints.down(ThemeBreakpoints.tablet)]: {
        backgroundPosition: 'left bottom',
        backgroundSize: 'cover',
      },
    }),
    heroSectionContainer: {
      paddingTop: spacing(6),
      paddingBottom: spacing(6),

      [breakpoints.up(ThemeBreakpoints.tablet)]: {
        paddingTop: spacing(15),
        paddingBottom: spacing(8),
      },
    },
    heroHeading: ({ textColor }) => ({
      gridRow: 1,
      gridColumn: 1,
      color: textColor || colors.sendwaveBlack,
      marginBottom: spacing(1),
    }),
    heroSearchBarWrapper: {
      'paddingTop': spacing(2),
      'paddingBottom': spacing(2),
      'width': '100%',
      'position': 'relative',

      [breakpoints.down(ThemeBreakpoints.tablet)]: {
        paddingBottom: spacing(2),
      },

      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        gridRow: 2,
        gridColumn: '1/-1',
        paddingBottom: spacing(0),
      },

      '& .MuiInputAdornment-root': {
        color: '#00000033',
      },

      '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
        color: 'rgba(0, 0, 0, 0.38)',
        fontSize: '24px',
        padding: important('14px 0px'),
        height: 0,

        [breakpoints.down(ThemeBreakpoints.mobile)]: {
          fontSize: '14px',
        },
      },
    },
    heroSearchBar: {
      'background': colors.white,
      'borderRadius': '999px',
      'padding': important(spacing(1.75, 3)),
      'outline': 0,
      'width': '100%',
      '&::before, &::after': {
        borderColor: 'transparent',
      },
      '&:hover:not(.Mui-disabled, .Mui-error):before': {
        borderColor: 'transparent',
        opacity: 0.5,
      },
    },
    heroSendCountrySection: {
      'display': 'flex',
      'justifyContent': 'flex-end',
      'alignItems': 'baseline',
      'position': 'relative',
      'zIndex': 1,

      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '23px',
        justifyContent: 'flex-start',
      },

      '& .MuiButton-root': {
        color: '#292C91',
        fontWeight: 700,
        fontSize: '20px',

        [breakpoints.down(ThemeBreakpoints.mobile)]: {
          fontSize: '16px',
        },
      },

      '& .MuiButton-iconSizeSmall': {
        'width': '24px',
        'height': '24px',

        '& svg': {
          fontSize: '24px',
        },
      },

      '& .MuiButton-endIcon': {
        marginLeft: '0px',
      },
    },
    heroHeaderSelect: {
      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        paddingLeft: 0,
      },
    },
    heroSendCountryText: {
      fontSize: '20px',
      fontWeight: 500,
      color: colors.grey,
      marginRight: '20px',

      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        fontSize: '16px',
        marginRight: '10px',
      },
    },
    footerImage: {
      width: '100%',
      height: '50px',
      position: 'absolute',
      top: 'unset',
      bottom: '0',
      left: '0',
      right: '0',
      transform: 'scaleX(-1)',
      zIndex: 0,

      [breakpoints.up(ThemeBreakpoints.mobile)]: {
        height: '90px',
      },

      [breakpoints.up(ThemeBreakpoints.tablet)]: {
        height: '130px',
      },

      [breakpoints.up(ThemeBreakpoints.desktop)]: {
        height: '170px',
      },
    },
  }),
);
