import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  section: {
    textAlign: 'center',
    padding: theme.spacing(4, 0),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 0),
    },
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default useStyles;
