import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { important, ThemeBreakpoints } from '@wr/web-ui';

import { colors } from './../../styles/variables';

type StyleProps = {
  backgroundColor?: string | null;
};

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  sectionWrapper: () => ({
    background: 'transparent',
    zIndex: 10,
    position: 'relative',
  }),
  gridWrapper: {
    display: 'grid',
    gap: theme.spacing(3.75),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: 'repeat(2, auto)',
    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      gridTemplateRows: 'repeat(3, auto)',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    },
    [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
      padding: theme.spacing(4, 3),
    },
    [theme.breakpoints.down(ThemeBreakpoints.xs)]: {
      padding: theme.spacing(4, 2),
    },
  },
  title: {
    gridColumn: '1/-1',
    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      gridRow: 1,
      gridColumn: 1,
    },
    [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
      fontSize: theme.spacing(5),
    },
    [theme.breakpoints.down(ThemeBreakpoints.xs)]: {
      fontSize: theme.spacing(3),
    },
  },
  questionsWrapper: {
    gridRow: 2,
    gridColumn: 2,
    maxWidth: theme.spacing(42.5),
    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      gridRow: 3,
      gridColumn: 1,
      maxWidth: 'none',
    },
  },
  topicsWrapper: {
    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      gridRow: 2,
      gridColumn: 1,
    },
  },
  searchResultsWrapper: {
    padding: theme.spacing(1, 3),
    backgroundColor: colors.sendwaveGray,
    borderRadius: important(theme.spacing(2)),
  },
  searchResultsEmpty: {
    padding: theme.spacing(3),
    textAlign: 'center',
    height: 'auto',
  },
  subAccordion: {
    'boxShadow': 'none',
    'padding': 0,
    'backgroundColor': colors.sendwaveGray,
    'borderBottom': '1px solid #D9D9D9',
    'borderRadius': important('0'),
    '&:last-child': {
      borderBottom: important('0'),
      marginBottom: 0,
    },

    '& .MuiAccordionDetails-root': {
      padding: '16px',
    },

    '& .MuiPaper-elevation': {
      padding: '0',
    },

    '& .MuiSvgIcon-root': {
      transform: 'rotate(90deg)',
      color: colors.sendwaveBlack,
    },

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(-180deg)',
    },
  },
  accordion: {
    'boxShadow': 'none',
    'padding': theme.spacing(4, 3),
    'backgroundColor': colors.sendwaveGray,
    'borderRadius': important(theme.spacing(2)),
    'marginBottom': theme.spacing(3),

    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
      padding: 0,
    },
  },
  accordionSummary: {
    'padding': 0,

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(-180deg)',
    },
    '& .MuiSvgIcon-root': {
      transform: 'rotate(90deg)',
      color: colors.sendwaveBlack,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      paddingRight: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'center',
    },
  },
  subAccordionSummary: {
    'padding': 0,

    '& .MuiAccordionSummary-content': {
      margin: 0,
      paddingRight: theme.spacing(1.5),
      alignItems: 'center',
    },

    '& .MuiSvgIcon-root': {
      transform: 'rotate(-180deg)',
      color: colors.sendwaveBlack,
    },

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(-180deg)',
    },
  },
  accordionSummaryAvatar: {
    width: theme.spacing(5),
    marginRight: theme.spacing(2),
    height: 'auto',
  },
  accordionSummaryTitle: {
    color: colors.sendwaveBlack,

    [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
      fontSize: theme.spacing(3.5),
    },
    [theme.breakpoints.down(ThemeBreakpoints.xs)]: {
      fontSize: theme.spacing(2.5),
    },
  },
  subAccordionSummaryTitle: {
    fontSize: theme.spacing(2.5),
    paddingBottom: theme.spacing(0),
  },
  accordionSummaryDescription: {
    color: colors.grey,
    backgroundColor: colors.white,
    padding: '14px 16px',
    borderRadius: '8px',
    fontSize: '16px',
    marginBottom: theme.spacing(2),
  },
  accordionDetails: {
    'padding': 0,
    'paddingLeft': '16px',

    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  accordionDetailsLink: {
    display: 'flex',
    alignItems: 'center',
    color: colors.sendwaveBlack,
    marginBottom: theme.spacing(1),
  },
  accordionDetailsLinkText: {
    paddingRight: theme.spacing(0.4),
  },
  clearSearchTerm: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
