import { BLOCKS } from '@contentful/rich-text-types';
import { Image } from '@wr/web-ui';
import React from 'react';

import { Button } from '../../button';
import {
  RichTextContentRenderer,
  RichTextProps,
} from '../../rich-text-content-renderer';
import { useStyles } from './card.styles';
import { InfoCardProps } from './card.types';

export const InfoCard: React.FC<InfoCardProps> = ({
  name,
  contentAlignment,
  backgroundColor,
  textColor,
  content,
  icon,
  link,
}) => {
  const classes = useStyles({ contentAlignment, hasButton: !!link });

  const contentProps = content?.json
    ? ({
        ...content,
        options: {
          [BLOCKS.PARAGRAPH]: {
            paragraph: true,
            variant: 'body2',
            fontSize: '1.125rem',
            color: textColor,
          },
          [BLOCKS.LIST_ITEM]: {
            variant: 'body2',
            fontSize: '1.125rem',
            color: textColor,
          },
        },
      } as RichTextProps)
    : null;

  const style = backgroundColor ? { backgroundColor } : {};
  return (
    <div
      data-testid={`info-cards-item-${name}`}
      className={classes.card}
      style={style}
    >
      <div className={classes.textContainer}>
        {icon && (
          <div className={classes.cardIconContainer}>
            <Image {...icon} className={classes.cardIcon} />
          </div>
        )}
        {contentProps && <RichTextContentRenderer {...contentProps} />}
      </div>
      {link && (
        <div className={classes.cardButtonContainer}>
          <Button
            {...link}
            data-testid={'info-cards-item-' + name + '-button'}
            tabIndex={-1}
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.cardButton}
          >
            {link.label}
          </Button>
        </div>
      )}
    </div>
  );
};
