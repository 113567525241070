import { BLOCKS } from '@contentful/rich-text-types';
import { colors, Hero } from '@wr/web-ui';
import React from 'react';

import { Anchor, AnchorProps, CexHeroProps } from '@/components';
import { Button, ButtonProps } from '@/components/button';
import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import {
  buildContentfulSidekickAttributes,
  convertTextToJsonNode,
} from '@/utils';

import { CexCurrencyConversionRates } from '../cex-currency-conversion-rates';

export const CexCurrencyHero: React.FC<CexHeroProps> = ({
  title,
  description,
  currencyInformation,
  isSectionOnTop,
}) => {
  return (
    <Hero<AnchorProps, ButtonProps, RichTextProps>
      name={currencyInformation.name || undefined}
      button={null}
      backgroundImage={null}
      trustpilotLogo={null}
      trustpilotLink={null}
      trustpilotMessage={null}
      trustpilotWidgetUrl={null}
      heading={convertTextToJsonNode([
        {
          type: BLOCKS.HEADING_1,
          value: title || '',
        },
      ])}
      subheading={convertTextToJsonNode([
        {
          type: BLOCKS.HEADING_6,
          value: description || '',
        },
      ])}
      AnchorComponent={Anchor}
      ButtonComponent={Button}
      RichTextComponent={RichTextContentRenderer}
      backgroundColor={colors.yellow}
      secondBackgroundColor={colors.pink}
      containerProps={buildContentfulSidekickAttributes(
        undefined,
        currencyInformation.__typename,
        currencyInformation.name,
      )}
      contentAlignment="top"
      contentFlexDirection="column"
      isSectionOnTop={!!isSectionOnTop}
    >
      {currencyInformation?.topCurrencyPairs?.items?.length ? (
        <CexCurrencyConversionRates sendCurrency={currencyInformation} />
      ) : null}
    </Hero>
  );
};
