import { BLOCKS } from '@contentful/rich-text-types';
import { Container } from '@mui/material';
import { Image, ImageProps } from '@wr/web-ui';

import { useImageByBreakpoint } from '@/hooks';

import { Button } from '../button';
import { RichTextContentRenderer } from '../rich-text-content-renderer';
import { useStyles } from './contained-banner.styles';
import { ContainedBannerProps } from './contained-banner.types';

export const ContainedBanner: React.FC<ContainedBannerProps> = ({
  name,
  backgroundColor,
  text,
  link,
  textBodyColor,
  textHeadingColor,
  textAlign,
  desktopBanner,
  mobileBanner,
}) => {
  const classes = useStyles({
    backgroundColor,
    textBodyColor,
    textHeadingColor,
    textAlign,
  });

  const renderImage = useImageByBreakpoint({
    mobile: mobileBanner as ImageProps,
    desktop: desktopBanner as ImageProps,
  });

  return (
    <section id={name || undefined} className={classes.section}>
      <Container
        maxWidth="lg"
        classes={{
          root: classes.container,
        }}
        data-testid="contained-banner-container"
      >
        {text && (
          <RichTextContentRenderer
            {...{
              ...text,
              options: {
                [BLOCKS.PARAGRAPH]: {
                  variant: 'body2',
                  className: classes.body,
                },
              },
            }}
          />
        )}
        {renderImage && <Image {...renderImage} className={classes.image} />}
        {link && (
          <Button
            {...link}
            data-testid="contained-banner-link"
            className={classes.link}
          >
            {link.label}
          </Button>
        )}
      </Container>
    </section>
  );
};
