import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ReviewsSectionStyles } from './reviews-sections.types';

export const useStyles = makeStyles<Theme, ReviewsSectionStyles>(theme => ({
  section: {
    'position': 'relative',
    'padding': theme.spacing(7, 0),
    'transform': 'skew(0deg, 4deg)',
    'margin': theme.spacing(8, 0, 5, 0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10, 0),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(13, 0),
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: ({ backgroundImage }: ReviewsSectionStyles) =>
        backgroundImage ? `url('${backgroundImage}')` : '',
      backgroundSize: 'cover',
      opacity: 0.15,
    },
  },
  container: {
    transform: 'skew(0deg, -4deg)',
    position: 'relative',
    zIndex: 1,
  },
  header: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  trustpilotWidget: {
    marginBottom: theme.spacing(5),
  },
}));
