import { contentfulSchema } from '@wr/web-shared';

import { getPageData } from '@/services';

export function getBreadcrumbs(
  pageData: Awaited<ReturnType<typeof getPageData>>,
) {
  return (
    (pageData as contentfulSchema.PageDataQuery)?.breadcrumbs?.items
      ?.slice()
      .sort((a, b) => {
        if (a?.slug === null) {
          return -1;
        } else if (b?.slug === null) {
          return 1;
        } else {
          return (a?.slug?.length ?? 0) - (b?.slug?.length ?? 0);
        }
      })
      .map(item => ({
        slug: item?.slug || null,
        name: item?.heading || null,
      })) || null
  );
}
