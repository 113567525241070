import { Typography } from '@mui/material';
import React from 'react';

import { PricingPublicParams } from '@/services/rest/pricing.service';
import { usePricingStore } from '@/state';

import { CexCurrencyExchangeRateProps } from './cex-currency-conversion-rates.types';

const defaultValue = '0.00';

export const CexCurrencyExchangeRate: React.FC<CexCurrencyExchangeRateProps> = ({
  sendCurrency,
  receiveCurrency,
}) => {
  const [localExchangeRate, setLocalExchangeRate] = React.useState(
    defaultValue,
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const { fetch: fetchRate } = usePricingStore(state => ({
    fetch: state.fetch,
  }));

  React.useEffect(() => {
    const fetchExchangeRates = async () => {
      setIsLoading(true);
      const payload = {
        amountType: 'SEND',
        receiveCurrency: receiveCurrency?.currencyCode,
        amount: 1,
        sendCurrency: sendCurrency?.currencyCode,
        sendCountryIso2: sendCurrency?.countryCode.toLowerCase(),
        receiveCountryIso2: receiveCurrency?.countryCode.toLowerCase(),
      } as PricingPublicParams;

      try {
        const result = await fetchRate(payload, 'en-us');
        if (result && result.baseExchangeRate) {
          const rate = parseFloat(result.baseExchangeRate.toString());
          setLocalExchangeRate(rate.toFixed(2));
        }
      } catch (error) {
        setLocalExchangeRate(defaultValue);
      } finally {
        setIsLoading(false);
      }
    };

    fetchExchangeRates();
  }, [fetchRate, sendCurrency, receiveCurrency]);

  return (
    <Typography component="span">
      {isLoading ? defaultValue : localExchangeRate}
    </Typography>
  );
};
