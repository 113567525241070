import {
  styled,
  Table,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { colors, Container as UIContainer, important } from '@wr/web-ui';

const StyledUIContainer = styled(UIContainer)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  marginBottom: theme.spacing(1),
  gap: theme.spacing(4),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(1),
  border: `1px solid ${colors.greyLight}`,
  borderSpacing: 'revert',

  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, 0),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  'display': 'flex',
  'padding': theme.spacing(0.5, 3),
  'borderBottom': `1px solid ${colors.greyLight}`,
  'background': important(colors.white),
  'justifyContent': 'space-between',

  '& .MuiTableCell-root': {
    background: colors.white,
    padding: ' 4px !important',
    borderBottom: 'none',
  },

  '& .MuiTableCell-root > p': {
    fontWeight: 500,
  },

  '&:last-child': {
    borderBottom: 'none',
  },
}));

const StyledTableTitle = styled(Typography)(({ theme }) => ({
  '& > *': {
    paddingRight: theme.spacing(1),
  },
}));

export {
  StyledUIContainer,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledTableTitle,
};
