import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment, useMemo } from 'react';

import { groupCurrenciesByAlphabet } from '@/services/contentful';

import { CexCurrencyCard } from '../cex-currency-card';
import { useStyles } from './cex-currency-grid.styles';
import { CexCurrencyGridProps } from './cex-currency-grid.types';

export const CexCurrencyGrid: React.FC<CexCurrencyGridProps> = ({
  currencies,
  sendCountryCode,
  sendCurrencyCode,
}) => {
  const classes = useStyles();

  const groups = useMemo(() => {
    const grouping = groupCurrenciesByAlphabet(currencies);
    return Object.keys(grouping).map(groupCode => ({
      groupCode,
      groupData: grouping[groupCode],
    }));
  }, [currencies]);

  return (
    <div className={classes.container} data-testid="cex-currency-grid">
      {groups.map(({ groupCode, groupData }) => (
        <Fragment key={groupCode}>
          <Box className={classes.alphabetContainer}>
            <Typography
              component="div"
              className={classes.alphabetText}
              data-testid="cex-currency-grid-group-code"
            >
              {groupCode}
            </Typography>
          </Box>

          <Grid className={classes.gridContent}>
            {groupData.map(currency => (
              <CexCurrencyCard
                key={currency.currencyCode}
                sendCountryCode={sendCountryCode}
                sendCurrencyCode={sendCurrencyCode}
                {...currency}
              />
            ))}
          </Grid>
        </Fragment>
      ))}
    </div>
  );
};
