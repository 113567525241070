import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  container: {
    background: theme.palette.common.white,
    minHeight: theme.spacing(55),
    borderRadius: theme.spacing(2),
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.12), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  },
  exchange: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.sendwaveBrown,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    height: theme.spacing(11),
  },
  header: {
    color: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  },
  exchangeRate: {
    fontSize: '1.5rem',
    color: theme.palette.common.white,
  },
  title: {
    fontSize: '1.75rem',
    marginTop: theme.spacing(2),
    fontWeight: 700,
    padding: theme.spacing(0, 4),
    textAlign: 'center',
    color: colors.sendwaveBrown,
  },
  description: {
    fontSize: '1rem',
    textAlign: 'center',
    padding: theme.spacing(0, 4),
    marginTop: theme.spacing(0.8),
    fontWeight: 500,
    color: colors.sendwaveBrown,
  },
  error: {
    color: colors.redNormal,
    textAlign: 'center',
    fontSize: 15,
  },
}));
