import { ContainerProps, Grid } from '@mui/material';
import clsx from 'clsx';

import { Container as UIContainer } from '../container';
import { Image } from '../image';
import { useStyles } from './text-section-grid.styles';
import { TextSectionGridProps } from './text-section-grid.types';

export function TextSectionGrid<RichText>({
  name,
  content,
  image,
  imageMobile,
  RichTextComponent,
  containerProps,
  maxWidth,
  backgroundColor,
  textColumnSize,
  imageColumnSize,
  imagePosition,
  imagePositionMobile,
  imageAlignment,
  columnAlignment,
  hasMobileImage,
  contentAlign,
  imageMaxWidth,
  mobileImageMaxWidth,
}: TextSectionGridProps<RichText>) {
  const classes = useStyles({
    backgroundColor,
    imagePosition,
    imagePositionMobile,
    imageAlignment,
    columnAlignment,
    hasMobileImage,
    contentAlign,
    imageMaxWidth,
    mobileImageMaxWidth,
  });

  return (
    <section id={name} {...containerProps} className={classes.textSection}>
      <UIContainer maxWidth={maxWidth as ContainerProps['maxWidth']}>
        <Grid
          data-testid="text-section-grid-container"
          container
          columnSpacing={!image ? 0 : 8}
          rowSpacing={{ xs: 3, sm: 0 }}
          className={classes.gridContainer}
        >
          {(image || imageMobile) && (
            <Grid
              data-testid="text-section-grid-image"
              item
              xs={12}
              sm={imageColumnSize || 6}
              className={clsx(classes.gridItem, classes.gridImageItem)}
            >
              {image && (
                <Image
                  {...image}
                  className={clsx(classes.image, 'image-desktop')}
                />
              )}
              {imageMobile && (
                <Image
                  {...imageMobile}
                  className={clsx(classes.image, 'image-mobile')}
                />
              )}
            </Grid>
          )}
          {content && (
            <Grid
              data-testid="text-section-grid-content"
              item
              xs={12}
              sm={textColumnSize || 6}
              className={classes.gridItem}
            >
              <div className={classes.contentContainer}>
                {content && <RichTextComponent {...content} />}
              </div>
            </Grid>
          )}
        </Grid>
      </UIContainer>
    </section>
  );
}
