import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  section: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  description: {
    color: colors.grey,
    fontSize: theme.spacing(2.5),
    textAlign: 'center',
  },
  cards: {
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  card: {
    'border': 0,
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'flex-end',
    'height': '20rem',
    'maxWidth': '20rem',
    '& .card-text-container': {
      background: alpha(colors.black, 0.4),
    },
    '& h3.MuiTypography-root': {
      color: colors.white,
    },
    '& p.MuiTypography-root': {
      color: colors.whiteSmokeGrey,
    },
  },
}));
