import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { AppBannerSectionProps } from './app-banner-section.type';

export const useStyles = makeStyles<Theme, AppBannerSectionProps>(theme => ({
  section: {
    padding: theme.spacing(4, 0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  gridImageItem: {
    justifyContent: 'center',
  },
  imageContainer: ({ imageMaxWidth, mobileImageMaxWidth }) => ({
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, -2),
    },

    '& img, & canvas': {
      'width': '100%',
      'height': 'auto',
      'max-width': imageMaxWidth ? `${imageMaxWidth}px` : '100%',

      '&.image-mobile': {
        'max-width': mobileImageMaxWidth ? `${mobileImageMaxWidth}px` : '100%',
        'display': 'none',
      },

      [theme.breakpoints.down('sm')]: {
        'display': 'none',

        '&.image-mobile': {
          display: 'block',
        },
      },
    },
  }),
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  ctaButton: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(2, 2),
    alignItems: 'center',
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'left',
    },

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  iconLink: {
    display: 'flex',
  },
}));
