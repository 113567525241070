import { SearchOption, toTitleCase } from '@wr/web-ui';

import { CexCurrency } from '@/services';

export const filterCurrencyOptions = (
  currencies: CexCurrency[],
  inputValue: string,
): SearchOption[] => {
  const normalizedInputValue = inputValue.toLowerCase();

  return currencies
    ?.filter(currency => {
      return (
        currency.currencyCode.toLowerCase().includes(normalizedInputValue) ||
        currency.currencyName.toLowerCase().includes(normalizedInputValue)
      );
    })
    .map(currency => {
      return {
        label: toTitleCase(currency?.currencyName || ''),
        url: currency.pageSlug.toLowerCase(),
      };
    });
};
