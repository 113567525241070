import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(({ breakpoints, spacing }) => ({
  heading: {
    [breakpoints.up('md')]: {
      textAlign: 'center',
    },

    [breakpoints.up('md')]: {
      textAlign: 'left',
      fontSize: '3.25rem', // 52px
      lineHeight: 1.07692307692, // 56px
    },
    [breakpoints.up('lg')]: {
      textAlign: 'left',
      fontSize: '3.5rem', // 56px
      lineHeight: 1.07142857143, // 60px
    },
  },
  textCenter: {
    [breakpoints.up('sm')]: {
      textAlign: 'center',
    },

    [breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  subheading: {
    [breakpoints.up('lg')]: {
      fontSize: '1.75rem', // 28px
      lineHeight: 1.14285714286, // 32px
    },
  },
  heading6: {
    'fontWeight': 700,

    '& a': {
      color: colors.sendwaveBrown,
    },
  },
  fullWidthHeading: {
    marginBottom: spacing(1),
  },
  fullWidthSubHeading: {
    marginBottom: spacing(1),

    [breakpoints.up('lg')]: {
      marginBottom: spacing(2),
    },
  },
  qrCodeContainer: {
    display: 'none',

    [breakpoints.up('md')]: {
      display: 'flex',
      maxWidth: '380px',
      borderRadius: '10px',
      marginTop: spacing(2),
    },
  },
  qrCodeImageContainer: {
    'display': 'flex',
    'alignItems': 'center',
    'width': '8rem',
    'borderRadius': '7px',
    'overflow': 'hidden',
    'boxShadow': '0px 3px 8px 0px rgba(91, 102, 112, 0.5)',
    'marginRight': spacing(2),
    'padding': spacing(0.5),
    'background': '#fff',

    '& img, & canvas': {
      width: '100%',
      height: 'auto',
    },
  },
  qrCodeDescription: {
    flex: 2,
    fontSize: '1.15rem',
    fontWeight: 700,
  },
}));
