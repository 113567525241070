import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  currencyCode: {
    color: palette.primary.contrastText,
    textDecorationLine: 'underline',
    marginTop: spacing(0.5),
    fontWeight: 700,
  },
}));
