import {
  Breadcrumbs as MuiBreadcrumbs,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useRouter } from 'next/router';
import { FC, useContext } from 'react';

import { AppContext } from '@/context';
import { useIntlMessages } from '@/hooks';
import { interpolate } from '@/utils';

import { Anchor } from '../anchor';
import { BreadcrumbsProps } from './breadcrumbs.types';

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs,
  color,
  linkColor,
  className,
}) => {
  const { originUri } = useContext(AppContext);
  const router = useRouter();

  const isMDUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const intlMessages = useIntlMessages();

  if (!breadcrumbs || !breadcrumbs.length) {
    return null;
  }

  return (
    <MuiBreadcrumbs
      maxItems={isMDUp ? undefined : 2}
      className={className}
      color={color}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        const breadcrumbName = interpolate(breadcrumb?.name, intlMessages);
        const absoluteUrl = `${originUri}/${router.locale}${
          breadcrumb?.slug ? '/' + breadcrumb?.slug : ''
        }`;

        // undefined slug means it's last breadcrumb and should render text only
        // null slug means it's homepage
        return index < breadcrumbs.length - 1 ? (
          <Anchor
            color={linkColor as string}
            variant="body2"
            underline="none"
            key={breadcrumb?.name}
            url={absoluteUrl}
          >
            {breadcrumbName}
          </Anchor>
        ) : (
          <Typography key={breadcrumb?.name} variant="body2" color={color}>
            {breadcrumbName}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
};
