import { Hidden } from '@mui/material';
import { DropdownDesktop, DropdownMobile } from '@wr/web-ui';
import React from 'react';

import { DropdownProps } from './dropdown.types';

export const Dropdown: React.FC<DropdownProps> = ({
  id,
  width,
  isOpen,
  anchorEl,
  children,
  showCloseIcon,
  onClose,
}) => (
  <>
    <Hidden mdUp>
      <DropdownMobile
        id={id}
        onClose={onClose}
        isOpen={isOpen}
        showCloseIcon={showCloseIcon}
      >
        {children}
      </DropdownMobile>
    </Hidden>
    <Hidden mdDown>
      <DropdownDesktop
        id={id}
        anchorEl={anchorEl}
        isOpen={isOpen}
        onClose={onClose}
        width={width}
      >
        {children}
      </DropdownDesktop>
    </Hidden>
  </>
);
