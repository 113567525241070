import { Paper, Rating, Typography } from '@mui/material';
import { Image, ImageProps } from '@wr/web-ui';

import { useStyles } from './reviews-card.styles';
import { ReviewsCardProps } from './reviews-card.types';

export const ReviewsCard = ({
  name,
  title,
  description,
  image,
  ratings,
  country,
}: ReviewsCardProps) => {
  const classes = useStyles();

  const props: ImageProps = {
    url: image?.url,
    width: image?.width,
    height: image?.height,
    alt: image?.alt,
    title: image?.title,
    className: classes.image,
  };

  return (
    <Paper
      data-testid={`reviewsCard-${name?.split(' ').join('-')}`}
      classes={{
        root: classes.card,
      }}
      elevation={0}
      square={false}
    >
      <Image {...props} />
      <Typography variant="h3" color="textSecondary" className={classes.title}>
        {title}
      </Typography>
      {country?.name && (
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.location}
        >
          {country.name}
        </Typography>
      )}
      <Rating
        name="read-only"
        value={ratings}
        className={classes.ratings}
        size={'large'}
        readOnly
      />
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.description}
      >
        {description}
      </Typography>
    </Paper>
  );
};
