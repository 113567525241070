import {
  contentfulSchema,
  EVENT,
  INTERACTION_NAME,
  pushToDataLayer,
} from '@wr/web-shared';
import { AppDownloadDropdownProps } from '@wr/web-ui';

export const getAppDownloadDropdownProps = (
  headerItems: contentfulSchema.HeaderSwFragment,
): AppDownloadDropdownProps | undefined => {
  if (!headerItems.mobileAppLeadingLinks) return undefined;
  const { mobileAppButtonText, qrCode } = headerItems.mobileAppLeadingLinks;
  if (!qrCode?.imageUrl) return undefined;
  return {
    headerVariant: 'light',
    title: mobileAppButtonText || 'Download app',
    description: qrCode?.description,
    url: qrCode?.imageUrl,
  } as AppDownloadDropdownProps;
};

export const getAllValidTopLevelLinks = (
  headerItems: contentfulSchema.HeaderSwFragment,
  isAuthenticated: boolean,
) => {
  const items: unknown = !isAuthenticated
    ? headerItems.notLoggedInMenuItemsCollection?.items ?? []
    : headerItems.loggedInMenuItemsCollection?.items ?? [];

  const typedItems = items as
    | contentfulSchema.Link[]
    | contentfulSchema.LinkGroup[];

  return [...typedItems].filter?.(item => !!item);
};

export const pushNavigationClickToDataLayer = (
  gaTrackName?: string | null,
  prefix?: string,
) => (): void => {
  if (!gaTrackName) {
    return;
  }

  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: INTERACTION_NAME.TOP_NAVIGATION_CLICK,
    linkName: prefix ? `${prefix} - ${gaTrackName}` : gaTrackName,
  });
};
