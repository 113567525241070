import { AppBarProps, Theme, useMediaQuery } from '@mui/material';
import {
  contentfulSchema,
  DEFAULT_LANGUAGE,
  DROPDOWN,
  EVENT,
  getLanguageNameAndCode,
  INTERACTION_NAME,
  LanguageCode,
  pushToDataLayer,
  setLanguageToCookie,
} from '@wr/web-shared';
import {
  getCountryCodeFromLocale,
  Header as UIHeader,
  HeaderVariant,
  ImageProps,
  isDarkTheme,
} from '@wr/web-ui';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useMemo } from 'react';

import { AppContext } from '@/context';

import { Anchor, AnchorProps } from '../anchor';
import { Button, ButtonProps } from '../button';
import { HeaderProps } from './header.types';
import {
  getAllValidTopLevelLinks,
  getAppDownloadDropdownProps,
  pushNavigationClickToDataLayer,
} from './header.utils';

const defaultLogoImageProps: Partial<ImageProps> = {
  loading: 'eager',
  priority: true,
};

export const Header: React.FC<HeaderProps> = ({
  blogTags,
  menuLabel,
  logoDark,
  logoDarkMobile,
  logoLight,
  logoLightMobile,
  headerTheme,
  headerPosition,
  languageFromCookie,
  linkProps,
  ...headerItems
}) => {
  const { regions } = useContext(AppContext);
  const router = useRouter();

  const { locale = DEFAULT_LANGUAGE, query } = useRouter();
  const actualSendCountry = query.selectfrom as string;
  const isSMUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const headerVariant = (headerTheme || 'dark') as HeaderVariant;
  const position = (headerPosition || 'absolute') as AppBarProps['position'];

  // Logo props
  // Changed isInternal to false for making hard refresh every time logo is clicked.
  const logo = {
    link: {
      underline: 'none',
      color: 'inherit',
      url: `/${locale}/`,
      isInternal: false,
      ariaLabel: isDarkTheme(headerVariant) ? logoDark?.alt : logoLight?.alt,
    },
    logoDark: {
      logo: logoDark
        ? {
            ...logoDark,
            ...defaultLogoImageProps,
          }
        : null,
      logoMobile: logoDarkMobile
        ? {
            ...logoDarkMobile,
            ...defaultLogoImageProps,
          }
        : null,
    },
    logoLight: {
      logo: logoLight
        ? {
            ...logoLight,
            ...defaultLogoImageProps,
          }
        : null,
      logoMobile: logoLightMobile
        ? {
            ...logoLightMobile,
            ...defaultLogoImageProps,
          }
        : null,
    },
  };

  // User Menu props
  const welcomeMessage = headerItems?.welcome || '';
  const userDropdownLinks: AnchorProps[] =
    headerItems.userDropdownMenuItemsCollection?.items
      .filter((item): item is AnchorProps => item !== null)
      .map(({ gaTrackName, name, label, ...rest }) => ({
        ...rest,
        'data-testid': name ? 'user-dropdown-link-' + name : undefined,
        'variant': undefined,
        'color': 'textPrimary',
        'underline': 'none',
        'children': label,
        'onClick': pushNavigationClickToDataLayer(
          gaTrackName,
          DROPDOWN.MY_ACCOUNT,
        ),
      })) || [];

  // Nav bar links mapping
  const allValidTopLevelLinks = getAllValidTopLevelLinks(
    headerItems,
    false,
  ).map(headerItem => {
    if (headerItem.__typename === 'Link') {
      const isButton =
        headerItem.variant === 'contained' || headerItem.variant === 'outlined';
      return {
        ...linkProps,
        'size': isButton && isSMUp ? 'medium' : 'small',
        'onClick': pushNavigationClickToDataLayer(headerItem.gaTrackName),
        'data-testid': `header-link-${headerItem.name}`,
        'isInternal': headerItem.isInternal,
        'children': headerItem.label,
        'name': headerItem.name,
        'url': headerItem.url,
        'variant': headerItem.variant,
      };
    }
  });

  const languages = useMemo(
    () =>
      languageFromCookie !== undefined
        ? [...new Set(regions.map(getCountryCodeFromLocale))]
            .sort((a, b) => a?.localeCompare(b || '') || -1)
            .map(item => getLanguageNameAndCode(item as LanguageCode))
        : undefined,
    [regions, languageFromCookie],
  );

  const languageSwitcherDataLayerProps = {
    event: EVENT.VISITOR_INTERACTION,
    dropdownName: DROPDOWN.LANGUAGE_SELECTOR,
  };

  const onLanguageOpen = () => {
    pushToDataLayer({
      ...languageSwitcherDataLayerProps,
      interactionName: INTERACTION_NAME.DROPDOWN_OPEN,
    });
  };

  const onLanguageChange = (value: string) => {
    const dropdownValue = value;

    pushToDataLayer({
      ...languageSwitcherDataLayerProps,
      interactionName: INTERACTION_NAME.DROPDOWN_SELECTION,
      dropdownValue,
    });
    setLanguageToCookie(dropdownValue);

    router.push(router.asPath, undefined, { locale: dropdownValue });
  };

  /**
   * Parse link data for display in web-ui
   */

  const color = isDarkTheme(headerVariant) ? 'secondary' : 'primary';

  const parseLinkData = useCallback(
    ({
      name,
      label,
      url,
      variant,
      color: linkColor,
      gaTrackName,
      isInternal,
      __typename,
    }: contentfulSchema.LinkFragment) => {
      const isButton = variant === 'contained' || variant === 'outlined';
      const size = isButton && isSMUp ? 'medium' : 'small';

      return {
        ...linkProps,
        'data-testid': `${__typename}-${name || ''}`,
        'color': isButton ? linkColor : color,
        url,
        variant,
        size,
        'onClick': pushNavigationClickToDataLayer(gaTrackName),
        isInternal,
        'children': label,
        name,
      } as ButtonProps;
    },
    [color, isSMUp, linkProps],
  );

  const submenuItems = useMemo(() => {
    if (!headerItems?.submenuItems?.length) {
      return undefined;
    }
    return headerItems.submenuItems
      .filter((link): link is contentfulSchema.Link => link !== null)
      .map(parseLinkData);
  }, [headerItems?.submenuItems, parseLinkData]);

  const appDownloadDropdownProps = getAppDownloadDropdownProps(headerItems);

  return (
    <UIHeader<AnchorProps, ButtonProps>
      appDownloadDropdownProps={appDownloadDropdownProps}
      isAuthenticated={false}
      headerVariant={headerVariant}
      welcomeMessage={welcomeMessage}
      myAccount={headerItems.myAccount || ''}
      menuLabel={menuLabel || ''}
      position={position}
      links={allValidTopLevelLinks as ButtonProps[]}
      logo={logo}
      userDropdownLinks={userDropdownLinks}
      ButtonComponent={Button}
      AnchorComponent={Anchor}
      onLanguageOpen={onLanguageOpen}
      onLanguageChange={onLanguageChange}
      submenuItems={submenuItems}
      languages={languages}
      language={languageFromCookie || DEFAULT_LANGUAGE}
      actualSendCountry={actualSendCountry}
      blogTags={blogTags}
      router={router}
    />
  );
};
