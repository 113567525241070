import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '@/styles';

const useStyles = makeStyles<Theme>(theme => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  flag: {
    marginRight: theme.spacing(1.5),
  },
  countryName: {
    color: colors.sendwaveBlack,
    fontSize: 16,
  },
  currencyCode: {
    paddingLeft: theme.spacing(1.5),
  },
}));

export default useStyles;
