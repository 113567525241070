import { Faq } from '@/state/sendwave/faq/faq.types';

import { FaqItem } from './faq-item.component';
import { StyledContainer } from './faq-item.styles';

export const FaqItems = ({ faqItems }: { faqItems: Faq[] }) => {
  if (!faqItems.length) return null;

  return (
    <StyledContainer>
      {faqItems.map(faq => (
        <FaqItem key={faq.id} {...faq} />
      ))}
    </StyledContainer>
  );
};
