import SearchIcon from '@mui/icons-material/Search';
import MuiAutocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React from 'react';

import useStyles from './autocomplete.styles';
import { AutocompleteProps } from './autocomplete.types';

function Autocomplete<T>(
  {
    name,
    placeholder,
    options,
    noOptionsText,
    searchText,
    setSearchText,
    inputRef,
    onClose,
    getOptionLabel,
    filterOptions,
    renderOption,
    onChange,
    isIOS,
  }: AutocompleteProps<T>,
  ref: React.Ref<HTMLElement>,
): React.ReactElement {
  const classes = useStyles();

  return (
    <MuiAutocomplete
      id={`${name}-search-input`}
      data-testid={`${name}-search-input`}
      open
      ref={ref}
      onClose={onClose}
      classes={{
        paper: classes.paper,
        popperDisablePortal: classes.popperDisablePortal,
        listbox: clsx({ [classes.listbox]: !isIOS }),
        option: classes.options,
      }}
      options={options}
      fullWidth
      disablePortal
      noOptionsText={noOptionsText}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      ListboxProps={{ className: classes.listboxPadding }}
      renderInput={params => (
        <TextField
          data-testid={`${name}-select-search`}
          {...params}
          classes={{
            root: classes.textField,
          }}
          placeholder={placeholder}
          value={searchText}
          onChange={e => setSearchText(e?.currentTarget?.value)}
          inputRef={inputRef}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          InputProps={{
            ...params.InputProps,
            classes: {
              input: classes.input,
              notchedOutline: classes.notchedOutline,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: null,
            value: searchText,
          }}
        />
      )}
      renderOption={renderOption}
      onChange={onChange}
    />
  );
}

export default React.forwardRef(Autocomplete) as <T>(
  props: AutocompleteProps<T> & { ref?: React.Ref<HTMLElement> },
) => ReturnType<typeof Autocomplete>;
