import clsx from 'clsx';
import React from 'react';
import Slider from 'react-slick';

import { CustomArrow } from './carousel.arrow';
import { useStyles } from './carousel.styles';
import { CarouselProps } from './carousel.types';

export const Carousel: React.FC<CarouselProps> = React.forwardRef<
  Slider,
  CarouselProps
>(({ settings = {}, children }, ref) => {
  const classes = useStyles();

  const defaultSettings = {
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    swipe: false,
    cssEase: 'linear',
    ...settings,
    className: clsx(classes.slider, settings.className),
    appendDots: (
      dots:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined,
    ) => <ul>{dots}</ul>,
    customPaging: () => <div className={classes.paginationItem} />,
    nextArrow: <CustomArrow variant="next" />,
    prevArrow: <CustomArrow variant="prev" />,
  };

  return (
    <Slider ref={ref} {...defaultSettings}>
      {children}
    </Slider>
  );
});

Carousel.displayName = 'Carousel';
