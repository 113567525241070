import { ANALYTICS_PAGE_TYPE, DEFAULT_LANGUAGE } from '@wr/web-shared';
import { getSendCountryCodeFromLocale } from '@wr/web-ui';
import { NextRouter } from 'next/router';
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next/types';

import {
  DEFAULT_COUNTRY_PAGE,
  QueryParams,
  REGION_CODES,
  TRAILING_SLASH,
} from '@/constants';
import { getRegionCode } from '@/services/contentful/region-code';
import { CountryData } from '@/state';
import { Faq } from '@/state/sendwave/faq/faq.types';
import { PageProps } from '@/types';

import {
  PageMetadataProps,
  RegionCodeKeys,
  SchemaConfig,
  SchemaData,
} from './page-metadata.types';

const COUNTRIES_PATH = `/${DEFAULT_COUNTRY_PAGE}/`;

export const getCanonicalUrl = ({
  originUri,
  locale,
  query,
  pathname,
  regions,
}: Pick<NextRouter, 'locale' | 'query' | 'pathname'> &
  Pick<PageMetadataProps, 'originUri' | 'regions'>) => {
  if (pathname === '404') {
    return null;
  }

  if (pathname.startsWith(COUNTRIES_PATH)) {
    const [receiveName, sendCode] = pathname
      .replace(COUNTRIES_PATH, '')
      .split('/');
    const language = locale?.split('-')[0];

    if (
      sendCode &&
      language &&
      regions?.includes(`${language.toLowerCase()}-${sendCode.toLowerCase()}`)
    ) {
      return `${originUri}/${language}-${sendCode}${COUNTRIES_PATH}${receiveName}`;
    }
  }

  // Get the value of the "page" parameter if it's present
  const pageParam = query?.page ? `${[QueryParams.page]}=${query?.page}` : '';

  // Create the canonical URL by combining the current path and the "page" parameter (if present)
  return `${originUri}/${locale}${pathname}${
    pageParam ? `?${pageParam}` : ''
  }`.replace(TRAILING_SLASH, '');
};

export const getLanguageFromLocale = (
  locale: GetServerSidePropsContext['locale'] | GetStaticPropsContext['locale'],
): string | undefined => locale?.split('-')[0];

export const getLanguageAlternates = ({
  originUri,
  pathname,
  noIndex,
  regions,
  analyticsPageType,
  sendCountries,
}: Pick<PageMetadataProps, 'originUri' | 'pathname' | 'noIndex'> & {
  sendCountries: CountryData[];
} & Pick<PageProps, 'regions' | 'analyticsPageType'>):
  | undefined
  | Array<{
      href: string;
      hreflang: string;
    }> => {
  const isHomepage = analyticsPageType === ANALYTICS_PAGE_TYPE.Homepage;
  const isBlogPage = analyticsPageType === ANALYTICS_PAGE_TYPE.Blog;

  // If a page only exists in one language - no Hreflang tag to be generated
  const isSingleLanguage = !isHomepage && (!regions || regions.length === 1);

  if (pathname === '404' || noIndex || isSingleLanguage) {
    return [];
  }

  if (regions) {
    return regions
      .filter(region => {
        const sendCountry = getSendCountryCodeFromLocale(
          region as string,
        )?.toUpperCase();

        return sendCountries && sendCountry
          ? sendCountries.map(country => country.iso2.includes(sendCountry))
          : true;
      })
      .map(region => {
        if (!isBlogPage && region === DEFAULT_LANGUAGE) {
          return;
        }

        const href = `${originUri}/${region}${pathname}`;

        if (isBlogPage) {
          return {
            href,
            hreflang: region,
          };
        }

        return {
          href,
          hreflang: (region as string).includes('-')
            ? region
            : `${region}-${region}`,
        };
      })
      .filter((hrefLang): hrefLang is {
        href: string;
        hreflang: string;
      } => Boolean(hrefLang))
      .concat({
        href: `${originUri}/${DEFAULT_LANGUAGE}${pathname}`,
        hreflang: 'x-default',
      });
  }
};

export const removeDashAndConvertToCamelCase = (str: string): string | null => {
  if (!str) return null;

  return str.replace(/(-.)/g, val => val[1].toUpperCase());
};

export const getActiveRegionCode = (
  regionCode: ReturnType<typeof getRegionCode>[],
  locale: string,
): string | null | undefined =>
  regionCode.map((codes: ReturnType<typeof getRegionCode>) => {
    for (const key in codes) {
      if (key === removeDashAndConvertToCamelCase(locale)) {
        if (codes[key as RegionCodeKeys]) {
          return REGION_CODES[key as RegionCodeKeys];
        }
      }
    }
  })[0];

const getOrganizationSchemaMarkup = (
  organizationSchema: PageMetadataProps['organizationSchema'],
): Record<string, unknown> | null => {
  if (!organizationSchema) return null;

  return {
    '@type': 'Organization',
    '@context': 'https://schema.org',
    'logo': organizationSchema?.image?.url || '',
    'sameAs':
      organizationSchema?.sameAsCollection?.items
        .filter((i): i is { value: string } => typeof i?.value === 'string')
        .map(i => i?.value) || [],
    'name': organizationSchema?.name || '',
    'description': organizationSchema?.description,
    'url': organizationSchema?.url || '',
    'email': organizationSchema?.email,
    'contactPoint': organizationSchema?.contactPointCollection?.items.map(
      contactPoint => ({
        telephone: contactPoint?.telephone,
        contactType: contactPoint?.contactType,
        areaServed: contactPoint?.areaServed,
      }),
    ),
    'address': {
      streetAddress: organizationSchema?.streetAddress,
      addressLocality: organizationSchema?.addressLocality,
      postalCode: organizationSchema?.postalCode,
      addressCountry: organizationSchema?.addressCountry,
    },
  };
};

const getFaqPageSchemaMarkup = (
  faqItems: Faq[],
): Record<string, unknown> | null => {
  if (!faqItems) return null;

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': faqItems
      .map(faqItem => {
        if (faqItem?.body) {
          return {
            '@type': 'Question',
            'name': faqItem.title,
            'acceptedAnswer': {
              '@type': 'Answer',
              'text': faqItem.body,
            },
          };
        }
        return null;
      })
      .filter(Boolean),
  };
};

const schemaConfigs: Record<string, SchemaConfig> = {
  [ANALYTICS_PAGE_TYPE.Homepage]: {
    schemaKey: 'organizationSchema',
    getSchema: (data: unknown) =>
      getOrganizationSchemaMarkup(
        data as PageMetadataProps['organizationSchema'],
      ),
    testId: 'organization-schema',
  },
  [ANALYTICS_PAGE_TYPE.FAQ]: {
    schemaKey: 'faqItems',
    getSchema: (data: unknown) => getFaqPageSchemaMarkup(data as Faq[]),
    testId: 'faq-page-schema',
  },
};

export const generateSchemaMarkup = (
  analyticsPageType: string,
  props: Pick<PageMetadataProps, 'organizationSchema' | 'modules' | 'faqItems'>,
): SchemaData[] => {
  const config = schemaConfigs[analyticsPageType];
  if (!config) return [];

  const schemaKey = config.schemaKey as keyof typeof props;
  const schemaData = props[schemaKey];

  if (!schemaData) return [];

  const schema = config.getSchema(schemaData as Record<string, unknown>);
  if (!schema) return [];

  return [
    {
      schema,
      testId: config.testId,
    },
  ];
};
