import { CircularProgress, Typography } from '@mui/material';
import { Container as UIContainer } from '@wr/web-ui';
import debounce from 'lodash.debounce';
import { useCallback, useContext, useEffect, useState } from 'react';

import { AppContext } from '@/context';
import { searchFaqItems } from '@/services/contentful/faq/faq.service';
import { useFaqsStore } from '@/state';
import { Faq } from '@/state/sendwave/faq/faq.types';

import { Button } from '../button';
import { FaqItems } from '../faq-items/faq-items.component';
import { useStyles } from './faq-section.styles';
import { FaqSectionModuleProps } from './faq-section.types';

export const FaqSection: React.FC<FaqSectionModuleProps> = ({
  title,
  clearText,
  resultsForText,
  emptySearchText,
}) => {
  const { locale } = useContext(AppContext);
  const [
    searchTerm,
    clearFilters,
    selectedCountry,
    faqItems,
    faqCountries,
  ] = useFaqsStore(state => [
    state.searchTerm,
    state.clearFilters,
    state.selectedCountry,
    state.faqItems,
    state.faqCountries,
  ]);

  const [searchResults, setSearchResults] = useState<Faq[] | undefined>();

  const getSearchResults = (searchTerm: string) => {
    searchFaqItems(searchTerm, locale).then(contentfulFaqs => {
      const faqs: Faq[] = [];
      contentfulFaqs?.faqItemCollection?.items.forEach(faq => {
        if (!faq) return;

        faqs.push({
          id: faq.sys.id,
          title: faq.title,
          body: faq.body,
          countries: [],
        });
      });

      setSearchResults(faqs);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchResults = useCallback(
    debounce(getSearchResults, 700),
    [],
  );

  useEffect(() => {
    setSearchResults(undefined);
    if (searchTerm) {
      debounceSearchResults(searchTerm);
    }
  }, [debounceSearchResults, searchTerm]);

  const classes = useStyles({ backgroundColor: '#fff' });

  return (
    <section className={classes.sectionWrapper} data-testid="faq-section">
      <UIContainer className={classes.gridWrapper}>
        <Typography
          className={classes.title}
          variant="h2"
          data-testid="faq-section-title"
        >
          {searchTerm ? (
            searchResults ? (
              <span>{`${
                searchResults?.length || 0
              } ${resultsForText} '${searchTerm}'`}</span>
            ) : null
          ) : (
            `${title} ${
              selectedCountry
                ? ` - ${
                    faqCountries?.find(
                      country => country.code === selectedCountry,
                    )?.name || ''
                  }`
                : ''
            }`
          )}
          {searchTerm && searchResults && (
            <Button
              data-testid="search-clear"
              variant="text"
              color={'primary'}
              onClick={clearFilters}
              className={classes.clearSearchTerm}
            >
              {clearText}
            </Button>
          )}
        </Typography>
        {searchTerm && !searchResults && (
          <div className={classes.searchResultsEmpty}>
            <CircularProgress />
          </div>
        )}

        {searchTerm && (
          <>
            {searchResults?.length === 0 && (
              <div className={classes.searchResultsEmpty}>
                {emptySearchText}
              </div>
            )}

            {searchResults && searchResults.length > 0 && (
              <div>
                <div className={classes.searchResultsWrapper}>
                  <FaqItems faqItems={searchResults} />
                </div>
              </div>
            )}
          </>
        )}

        {!searchTerm && (
          <div className={classes.topicsWrapper}>
            <FaqItems faqItems={faqItems} />
          </div>
        )}
      </UIContainer>
    </section>
  );
};
