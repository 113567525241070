import { Grid, Typography } from '@mui/material';
import { Card, Container } from '@wr/web-ui';
import React from 'react';

import { useStyles } from './leadership-team.styles';
import { LeadershipTeamProps } from './leadership-team.types';

export const LeadershipTeam: React.FC<LeadershipTeamProps> = props => {
  const classes = useStyles();
  const { title, description, teamMembers } = props;

  return (
    <section className={classes.section} data-testid="leadership-team-section">
      <Container className={classes.container}>
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
        <Typography className={classes.description} paragraph>
          {description}
        </Typography>
        <Grid
          container
          direction="row"
          className={classes.cards}
          data-testid="photo-cards-grid"
          spacing={3}
        >
          {teamMembers.items.map(member => {
            return (
              <Grid
                item
                key={member.sys.id}
                data-testid={`leadership-team-photo-card-${member.sys.id}`}
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Card
                  backgroundImage={{
                    ...member?.image,
                    fill: true,
                    style: {
                      objectFit: 'cover',
                      objectPosition: 'center',
                    },
                  }}
                  className={classes.card}
                  header={member?.name ?? ''}
                  description={member?.jobTitle ?? ''}
                  name={(member?.name ?? '').toLowerCase().replace(' ', '-')}
                  backgroundColor={null}
                  chipLabel={null}
                  button={null}
                  link={null}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </section>
  );
};
