import { BLOCKS } from '@contentful/rich-text-types';
import { contentfulSchema } from '@wr/web-shared';
import {
  TextSectionGrid as UITextSectionGrid,
  TextSectionGridColumnAlignment,
  TextSectionGridImageAlignment,
  TextSectionGridImagePosition,
  TextSectionGridImagePositionMobile,
  useImageForBreakpoint,
} from '@wr/web-ui';
import React from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { Asset } from '@/components/text-section/text-section.type';
import { buildContentfulSidekickAttributes } from '@/utils';

export function TextSectionGrid({
  sys,
  __typename,
  name,
  content,
  imageCollection,
  imageMobile,
  maxWidth,
  backgroundColor,
  textColumnSize,
  imageColumnSize,
  imagePosition,
  imagePositionMobile,
  imageAlignment,
  columnAlignment,
  contentAlign,
}: contentfulSchema.TextSectionV2Fragment) {
  const image = useImageForBreakpoint(
    imageCollection?.items[0] as Asset,
    imageCollection?.items[1] as Asset,
    imageCollection?.items[2] as Asset,
  );

  return (
    <UITextSectionGrid<RichTextProps | null>
      name={name || undefined}
      image={image || null}
      imageMobile={imageMobile || null}
      hasMobileImage={!!imageMobile}
      content={
        content?.json
          ? ({
              ...content,
              options: {
                [BLOCKS.PARAGRAPH]: {
                  paragraph: true,
                  variant: 'body2',
                },
                [BLOCKS.LIST_ITEM]: {
                  variant: 'body2',
                },
              },
            } as RichTextProps)
          : null
      }
      maxWidth={maxWidth || null}
      textColumnSize={textColumnSize || null}
      imageColumnSize={imageColumnSize || null}
      backgroundColor={backgroundColor || null}
      imagePosition={imagePosition as TextSectionGridImagePosition}
      imagePositionMobile={
        imagePositionMobile as TextSectionGridImagePositionMobile
      }
      imageAlignment={imageAlignment as TextSectionGridImageAlignment}
      columnAlignment={columnAlignment as TextSectionGridColumnAlignment}
      contentAlign={contentAlign as TextSectionGridImageAlignment}
      RichTextComponent={RichTextContentRenderer}
      containerProps={buildContentfulSidekickAttributes(
        sys?.id,
        __typename,
        name,
      )}
      imageMaxWidth={image?.width}
      mobileImageMaxWidth={imageMobile?.width}
    />
  );
}
