import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { InfoCardsProps } from './info-cards.types';

export const useStyles = makeStyles<Theme, InfoCardsProps>(theme => ({
  section: ({ backgroundColor }) => ({
    ...(backgroundColor ? { backgroundColor } : {}),
    padding: theme.spacing(6, 0),
  }),
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    backgroundColor: theme.palette.background.paper,
  },
}));
