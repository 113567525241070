import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { important } from '@wr/web-ui';

import { colors } from '@/styles';

import { ContainedBannerStyles } from './contained-banner.types';

export const useStyles = makeStyles<Theme, ContainedBannerStyles>(theme => ({
  section: ({ backgroundColor, textAlign }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    textAlign: (textAlign as React.CSSProperties['textAlign']) || 'center',
    padding: '32px 0',
    backgroundColor: backgroundColor || colors.white,

    [theme.breakpoints.up('sm')]: {
      padding: '128px 0',
    },
  }),
  container: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  body: ({ textBodyColor }) => ({
    fontSize: '14px',
    color: textBodyColor || colors.grey,

    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
  }),
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    marginBottom: theme.spacing(2),
  },
  link: {
    'display': 'block',
    'color': colors.sendwaveBrown,
    'backgroundColor': colors.sendwaveYellow,
    'width': '100%',
    'borderRadius': '28px',
    'fontSize': '20px',
    'fontWeight': 700,
    'margin': `${theme.spacing(1.5)} auto 0`,

    [theme.breakpoints.up('sm')]: {
      width: '288px',
    },

    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(5),
    },

    '&:hover': {
      color: colors.white,
      backgroundColor: important(colors.sendwaveBrown),
    },
  },
}));
