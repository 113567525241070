import { BLOCKS } from '@contentful/rich-text-types';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { useAccordionToggle } from '@/hooks';
import { colors } from '@/styles';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '../rich-text-content-renderer';
import { useStyles } from './accordion.styles';
import { AccordionProps } from './accordion.types';

export const Accordion: React.FC<AccordionProps> = ({
  name,
  heading,
  items,
  backgroundColor,
}) => {
  const classes = useStyles({
    backgroundColor,
  });

  const [expandedPanel, setExpandedPanel] = useState<string>();

  const { handlePanelIcon, handleToggleAccordion } = useAccordionToggle(
    expandedPanel as string | undefined,
  );

  return (
    <section
      id={name || ''}
      className={classes.accordionSection}
      data-testid="accordion-component"
    >
      <Container
        maxWidth="lg"
        classes={{
          root: classes.container,
        }}
      >
        <Typography
          className={classes.accordionHeading}
          variant="h2"
          data-testid="accordion-component-heading"
        >
          {heading}
        </Typography>
        {items.items.map(
          ({ sys, title, content, contentBackgroundColor }, index: number) => {
            const panelIndex = `panel_${index}`;

            return (
              <MuiAccordion
                className={classes.accordionItem}
                data-testid={`accordion-item-${sys?.id}`}
                expanded={expandedPanel === panelIndex}
                onChange={() =>
                  handleToggleAccordion(setExpandedPanel, panelIndex)
                }
                key={sys?.id}
              >
                <AccordionSummary
                  className={classes.accordionItemSummary}
                  expandIcon={handlePanelIcon(panelIndex)}
                >
                  <Typography
                    variant="h6"
                    className={classes.accordionItemSummaryTitle}
                  >
                    {title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: contentBackgroundColor || colors.white,
                  }}
                  className={classes.accordionItemDetailsContent}
                >
                  {content && (
                    <RichTextContentRenderer
                      json={content.json}
                      links={content?.links as RichTextProps['links']}
                      options={{
                        [BLOCKS.PARAGRAPH]: {
                          paragraph: true,
                          variant: 'body2',
                        },
                        [BLOCKS.LIST_ITEM]: {
                          variant: 'body2',
                        },
                      }}
                    />
                  )}
                </AccordionDetails>
              </MuiAccordion>
            );
          },
        )}
      </Container>
    </section>
  );
};
