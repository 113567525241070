import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  buttonGroup: {
    'height': theme.spacing(6),
    'marginBottom': 15,
    '& .MuiToggleButton-root.Mui-selected': {
      borderColor: colors.sendwaveYellow,
      color: colors.sendwaveBlack,
    },
  },
  toggleButton: {
    fontWeight: 700,
    color: colors.sendwaveBlack,
    borderColor: colors.sendwaveYellow,
    textTransform: 'capitalize',
    lineHeight: theme.spacing(1.8),
    fontSize: theme.spacing(1.5),
  },
  toggleContent: {
    display: 'flex',
    textAlign: 'left',
    width: '100%',
    alignItems: 'center',
  },
  accordionSummaryAvatar: {
    width: 17,
    height: 22,
    marginRight: theme.spacing(1),
  },
}));
