import { Grid, Typography } from '@mui/material';
import { contentfulSchema } from '@wr/web-shared';
import { Container as UIContainer } from '@wr/web-ui';
import React from 'react';

import { InfoCard } from './card/card.component';
import { InfoCardProps } from './card/card.types';
import { useStyles } from './info-cards.styles';

export const InfoCards: React.FC<contentfulSchema.InfoCardsFragment> = ({
  header,
  cards,
  backgroundColor,
}) => {
  const classes = useStyles({ backgroundColor });

  return (
    <section data-testid="info-cards" className={classes.section}>
      <UIContainer className={classes.container}>
        {header && (
          <Typography
            data-testid="info-cards-header"
            variant="h2"
            className={classes.header}
          >
            {header}
          </Typography>
        )}
        <Grid
          data-testid="info-cards-grid"
          container
          columnSpacing={3}
          rowSpacing={3}
          className={classes.gridContainer}
        >
          {cards?.items.map(card => {
            return (
              <Grid
                key={card?.sys.id}
                data-testid="info-cards-grid-item"
                item
                xs={12}
                sm={6}
                lg={3}
                className={classes.gridItem}
              >
                <InfoCard {...(card as InfoCardProps)} />
              </Grid>
            );
          })}
        </Grid>
      </UIContainer>
    </section>
  );
};
