import {
  APP_NAME,
  contentfulSchema,
  INTERACTION_NAME,
  pushToDataLayer,
} from '@wr/web-shared';
import { AppBanner as UIAppBanner, Image } from '@wr/web-ui';
import { FC, useContext, useEffect, useState } from 'react';

import { ANDROID, IOS } from '@/constants';
import { AppContext } from '@/context';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Anchor, AnchorProps } from '../anchor';

export const AppBanner: FC<contentfulSchema.AppBannerFragment> = ({
  sys,
  name,
  appStoreIconLink,
  playStoreIconLink,
  bannerTitle,
  bannerDescription,
  image,
  __typename,
}) => {
  const [fullUrl, setFullUrl] = useState('');
  useEffect(() => {
    setFullUrl(window.location.href);
  }, []);
  const { osName, locale, analyticsPageType } = useContext(AppContext);

  const shouldRenderPlayStoreLink = osName !== IOS;
  const shouldRenderAppStoreLink = osName !== ANDROID;

  const dataLayerProps = {
    event: INTERACTION_NAME.DOWNLOAD_APP_CLICK,
    page_type: analyticsPageType,
    page_name: fullUrl,
    page_language: locale,
  };

  const onClickAppStoreLink = (): void => {
    pushToDataLayer({ ...dataLayerProps, app_name: APP_NAME.IOS });
  };

  const onClickPlayStoreLink = (): void => {
    pushToDataLayer({ ...dataLayerProps, app_name: APP_NAME.ANDROID });
  };

  return (
    <UIAppBanner<AnchorProps>
      name={name || ''}
      bannerTitle={bannerTitle || undefined}
      bannerDescription={bannerDescription || undefined}
      appStoreIconLink={
        shouldRenderAppStoreLink
          ? {
              ...appStoreIconLink,
              'data-testid': 'app-banner-app-store-link',
              'openInNewTab': true,
              'isInternal': false,
              'onClick': onClickAppStoreLink,
              'children': appStoreIconLink?.image ? (
                <Image {...appStoreIconLink.image} fill={false} />
              ) : null,
            }
          : null
      }
      playStoreIconLink={
        shouldRenderPlayStoreLink
          ? {
              ...playStoreIconLink,
              'data-testid': 'app-banner-play-store-link',
              'openInNewTab': true,
              'isInternal': false,
              'onClick': onClickPlayStoreLink,
              'children': playStoreIconLink?.image ? (
                <Image {...playStoreIconLink.image} fill={false} />
              ) : null,
            }
          : null
      }
      image={image || null}
      LinkComponent={Anchor}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
