import { contentfulSchema } from '@wr/web-shared';
import { CardProps, Cards as UICards } from '@wr/web-ui';
import React from 'react';

import { buildContentfulSidekickAttributes } from '@/utils';

import { Anchor, AnchorProps } from '../anchor';
import { Button, ButtonProps } from '../button';

export const Cards: React.FC<contentfulSchema.CardsFragment> = ({
  name,
  __typename,
  sys,
  header,
  description,
  carouselText,
  cardsCollection,
  paymentText,
}) => {
  const uiCards = (cardsCollection?.items as contentfulSchema.CardFragment[])
    .filter(card => card && !card?.payoutMethodId)
    .map(card => {
      // const publishedTime = card?.sys?.publishedAt
      //   ? formatDistanceToNow(card.sys.publishedAt, router.locale)
      //   : '';

      return {
        name: card?.payoutMethodId || card?.name,
        backgroundColor: card?.backgroundColor || null,
        backgroundImage: card?.backgroundImage
          ? {
              ...card.backgroundImage,
              fill: true,
              style: {
                objectFit: 'cover',
                objectPosition: 'center',
              },
            }
          : null,
        header: card?.header,
        description: card?.description,
        link: card?.link
          ? {
              ...card.link,
              children: card.link.label,
              underline: 'none',
            }
          : null,
        button: card?.link
          ? card?.backgroundImage
            ? {
                children: card.link.label,
              }
            : {
                ...card.link,
                children: card.link.label,
              }
          : null,
        containerProps: buildContentfulSidekickAttributes(
          card?.sys.id,
          card?.__typename,
          card?.name,
        ),
      };
    }) as CardProps<AnchorProps, ButtonProps>[];

  return (
    <UICards<AnchorProps, ButtonProps>
      name={name || undefined}
      header={header || undefined || null}
      description={description || undefined || null}
      carouselText={carouselText || null}
      AnchorComponent={Anchor}
      ButtonComponent={Button}
      messages={{
        previous: 'previous',
        next: 'next',
      }}
      containerProps={buildContentfulSidekickAttributes(
        sys?.id,
        __typename,
        name,
      )}
      cards={uiCards}
      paymentText={paymentText || null}
      paymentIcons={null}
    />
  );
};
