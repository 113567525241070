import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { HONEYPOT_FIELD_NAME } from '@/constants/web-form';
import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  section: {
    padding: theme.spacing(4, 0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  disclaimer: {
    marginBottom: theme.spacing(1),
    color: colors.greyNormal,
    fontSize: '16px',
    fontStyle: 'italic',
  },
  gridContainer: {
    justifyContent: 'center',
  },
  formError: {
    margin: theme.spacing(3, 0),
    backgroundColor: colors.redNormal,
    color: colors.white,
    padding: theme.spacing(2),
    borderRadius: '4px',
    fontSize: '1rem',
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  // Honeypot field
  [HONEYPOT_FIELD_NAME]: {
    display: 'none',
  },
}));
